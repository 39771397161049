import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Box } from "@mui/material";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import tippy from "tippy.js"; // For better tooltips
import "tippy.js/dist/tippy.css"; // Tippy.js styles

const CalenderContent = () => {
  const [events, setEvents] = useState([]);
  const loginData = useSelector((state) => state.auth.Auth);

  // Fetch data based on the view type and date range
const fetchEvents = async (viewType, dateInfo) => {
  try {
    let url;
    const year = dateInfo.start.getFullYear();

    // Use the end date of the view to determine the visible month
    let month = dateInfo.end.getMonth(); // Get the month from the end date and adjust to 1-based month
	if(month===0){
		month=12
	}
    console.log("Raw month from end date (zero-based):", dateInfo.end.getMonth()); // Log the zero-based month
    console.log("Human-readable month (1-12) from end date:", month); // Log the human-readable month from the end date

    if (viewType === "dayGridMonth") {
      url = `${config.apiUrl}employer/interview-calendar/month?year=${year}&month=${month}`;
    } else if (viewType === "timeGridWeek") {
      const date =  dateInfo.end.toISOString().split("T")[0];
      url = `${config.apiUrl}employer/interview-calendar/week?date=${date}`;
    } else if (viewType === "timeGridDay") {
      const date =  dateInfo.end.toISOString().split("T")[0];
      url = `${config.apiUrl}employer/interview-calendar/day?date=${date}`;
    }

    console.log("API URL:", url); // Check the URL being generated

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${loginData.access_token}`,
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Map API response to FullCalendar's event format
    const formattedEvents = data.map((item) => ({
      id: item.id,
      title: item.title,
      start: `${item.interview_date}T${item.start}`,
      end: `${item.interview_date}T${item.end}`,
      extendedProps: item.details,
      
    }));

    setEvents(formattedEvents);
  } catch (error) {
    console.error("Error fetching events:", error);
  }
};

  
  // Triggered when the calendar's date range or view changes
  const handleDatesSet = (dateInfo) => {
    const viewType = dateInfo.view.type; // Determine the current view (month, week, day)
    fetchEvents(viewType, dateInfo); // Ensure fetching aligns with the current view
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, mt: 10 ,overflowX: "auto",}} className="mainContent">
      <FullCalendar
        initialDate={new Date()} // Default to the current date
        initialView="dayGridMonth"
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        events={events}
        datesSet={handleDatesSet} // Ensure API is called with the correct range
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        height="auto" // Adjusts height dynamically
        aspectRatio={1.8} // Adjust aspect ratio for different screen sizes
        contentHeight="auto"
        eventContent={(arg) => {
          // Function to format date and time to AM/PM
          const formatTime = (date) => {
            if (!date) return '';
            return new Intl.DateTimeFormat('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              hour12: true,
            }).format(date);
          };
        
          const start = formatTime(arg.event.start);
          const end = formatTime(arg.event.end);
        
          return (
            <div className=" overflow-scroll" >
              <div className="calender-text ">{arg.event.title}</div>
              <div className="calender-text ">
                {start} - {end}
              </div>
            </div>
          );
        }}
        
        eventDidMount={(info) => {
          // Attach tooltip using Tippy.js
          const details = info.event.extendedProps;
  
          tippy(info.el, {
            content: `
              <strong>${info.event.title}</strong><br/>
              <em>Applicant:</em> ${details.applicant_name}<br/>
              <em>Job:</em> ${details.job_title}<br/>
              <em>Stage:</em> ${details.interview_stage}<br/>
              <em>Type:</em> ${details.interview_type}<br/>
              <em>Status:</em> ${details.status}<br/>
              <em>Interviewer:</em> ${details.interviewer_name}
            `,
            allowHTML: true,
            theme: "light",
          });
        }}
      />
    </Box>
  );
};

export default CalenderContent;
