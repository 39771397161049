import React, { useEffect, useState }  from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DrawerHeader from './DrawerHeader';
import { Link } from 'react-router-dom';
import config from '../../config/config';
import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip'; 
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import FeedBack from './feedBack';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  p: 4,
  borderRadius: "20px"
};

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerStyled = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    height:"100%",
    zIndex:0,
    
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

// remove th currentPage from the local storage while swiching beetween menu



const menuList = [
  {
    name:"Dashboard",
    icon:'images/icons/home.svg',
    link:"dashboard"
  },
  {
    name:"Find Job",
    icon:"images/icons/find_job.svg",
    link:"find-job"
  },
  {
    name:"Recommendations",
    icon:"images/icons/recommendations.svg",
    link:"recomendation"
  },
  {
    name:"My Applications",
    icon:"images/icons/applications.svg",
    link:"my-applications"
  },
  {
    name:"Saved Jobs",
    icon:"images/icons/saved.svg",
    link:"saved-jobs"
  },
  // {
  //   name:"Companies",
  //   icon:"images/icons/company.svg",
  //   link:""
  // },
  {
    name:"Interviews",
    icon:"images/icons/calender.svg",
    link:"interviews"
  },
  {
    name:"Settings",
    icon:'images/icons/setting.svg',
    link:"setting"
  },
]
const menuList2 = [
  // {
  //   name:"Settings",
  //   icon:'images/icons/setting.svg',
  //   link:"setting"
  // },
  // {
  //   name:"Help centre",
  //   icon:"images/icons/help.svg",
  //   link:""
  // }
]
export default function Drawer() {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const theme = useTheme();
  const open = useSelector((state) => state.auth.drawerOpen);
  const dispatch = useDispatch()
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const { pathname } = useLocation();

  const handleOpenFeedBack = () => {
    console.log("Opening Feedback Modal");
    setIsFeedbackOpen(true);
  };


  const logout = async () => {
    
    dispatch({
      type: 'LOGOUT', 
    });

  await  localStorage.removeItem('formData');
  await  localStorage.removeItem('token');
  
  navigate('/candidate/login', { replace: true });
    setOpenModal(false); 
  }

  useEffect(() => {
    localStorage.removeItem('currentPage');
  }, []);

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);
  return (
    <DrawerStyled variant="permanent" open={open} >
      <DrawerHeader />
      <List>
        {menuList.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
          <div className='flex  justify-center items-center'>
              <div
                        className={`w-[4px] h-[32px] rounded-[4px]   ${
                          pathname === config.candidateUrl+item.link
                            ? " bg-[#516AF4]"
                            : " bg-transparent "
                        }`}
              ></div>
            <ListItemButton
            component={Link}
             to={`${config.candidateUrl}${item.link}`}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  borderRadius: 2, 
                  mx:1
                }}
                className={`${
                    pathname === config.candidateUrl+item.link
                      ? "bg-active-button-gradient text-[#516AF4]"
                      : "text-[#5E5E5E]"
                  }`}
            >
          <Tooltip title={item.name} placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
              >
               <img src={item.icon}/>
              </ListItemIcon>
              </Tooltip> 
              <ListItemText  className='menu-name'  sx={{ opacity: open ? 1 : 0 }}>{item.name}</ListItemText>
            </ListItemButton>
          </div>
          </ListItem>
        ))}

        <ListItem disablePadding sx={{ display: "block" }}>
        <div className='flex  justify-center items-center'>
              <div
                className={`w-[4px] h-[32px] rounded-[4px]   ${
                  isFeedbackOpen
                    ? " bg-[#516AF4]"
                    : " bg-transparent "
                }`}
              ></div>
          <ListItemButton
            component="a"
            onClick={handleOpenFeedBack}
            sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  borderRadius: 2, 
                  mx:1
                }}
                // className={`${
                //     isFeedbackOpen
                //       ? "bg-active-button-gradient text-[#516AF4]"
                //       : "text-[#5E5E5E]"
                //   }`}
          >
            <Tooltip title="Feedback" placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: "center",
                }}
              >
                <img src="images/icons/feedback.svg" alt="Feedback Icon" />
              </ListItemIcon>
            </Tooltip>
            <ListItemText className="menu-name " sx={{ opacity: open ? 1 : 0 }}>
              Feedback
            </ListItemText>
          </ListItemButton>
          </div>
        </ListItem>
      </List>
      <List>
        {menuList2.map((item, index) => (
          <ListItem key={index} disablePadding sx={{ display: 'block' }}>
            <div className='flex  justify-center items-center'>
              <div
                        className={`w-[4px] h-[32px] rounded-[4px]   ${
                          pathname === config.candidateUrl+item.link
                            ? " bg-[#516AF4]"
                            : " bg-transparent "
                        }`}
              ></div>
              <ListItemButton
              component={Link}
              to={`${config.candidateUrl}${item.link}`}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                  borderRadius: 2, 
                  mx:1
                }}
                className={`${
                    pathname === config.candidateUrl+item.link
                      ? "bg-active-button-gradient text-[#516AF4]"
                      : "text-[#5E5E5E]"
                  }`}
              >
                <Tooltip title={item.name} placement="right">
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                <img src={item.icon}/>
                </ListItemIcon>
                </Tooltip>
                <ListItemText  className='menu-name'  sx={{ opacity: open ? 1 : 0 }}>{item.name}</ListItemText>
              </ListItemButton>
            </div>
          </ListItem>
        ))}


      </List>
      <FeedBack open={isFeedbackOpen} setOpen={setIsFeedbackOpen} />


      <List style={{top:"10%"}}>
          <ListItem  disablePadding sx={{ display: 'block' }}>
          <div className='flex  justify-center items-center'>
              <div
                className={`w-[4px] h-[32px] rounded-[4px]   ${
                  isFeedbackOpen
                    ? " bg-[#516AF4]"
                    : " bg-transparent "
                }`}
              ></div>
            <ListItemButton
            onClick={handleOpen}
            component={Link}
            //  to={`${config.employerUrl}${item.link}`}
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
               <Tooltip title="Log-out" placement="right">
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 2 : 'auto',
                  justifyContent: 'center',
                }}
              >
               <img src="images/icons/logout.svg"/>
              </ListItemIcon>
              </Tooltip>
              <ListItemText  className='menu-name'  sx={{ opacity: open ? 1 : 0 }}>Log-out</ListItemText>
            </ListItemButton>
          </div>
          </ListItem>
      </List>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Are you sure you want to logout?
          </Typography>
          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={handleClose}
              className="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              No
            </button>
            <button
              onClick={logout}
              className="py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium theme-btn rounded-lg"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </DrawerStyled>
  );
}
