import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../dashboard/Appbar';
import Drawer from '../dashboard/Drawer';
import Request_reschedule from './requests';

export default function Request() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar />
      <Drawer />
      <Request_reschedule />
    </Box>
  );
}
