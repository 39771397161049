
import React from 'react';
import { Box, CssBaseline } from '@mui/material';
import AppBar from '../dashboard/Appbar';
import Drawer from '../dashboard/Drawer';
import SubscriptionMain from './subscription';

export default function Subscription() {
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar /> */}
      {/* <Drawer /> */}
      <SubscriptionMain />
    </Box>
  );
}

