import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Chip,
  Container,
  Grid,
  Box,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import StarIcon from "@mui/icons-material/Star";
import {
  Dialog,
  DialogContent,
  Divider,
  TextField,
} from "@mui/material";
import { styled } from "@mui/system";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";

// const StyledDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiPaper-root": {
//     background: "linear-gradient(to right, #c4f6ff, #efd8ff)",  
//     color: "#fff",
//     padding: theme.spacing(3),
//     borderRadius: "12px",
//   },
// }));


const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    background: 'linear-gradient(145deg, #c4f6ff 0%, #efd8ff 100%)',
    borderRadius: '16px',
    padding: theme.spacing(2),
    maxWidth: '600px',
    width: '100%',
  },
}));

const SectionCard = styled(Box)(({ theme }) => ({
  background: '#ffffff',
  borderRadius: '12px',
  padding: theme.spacing(3),
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  marginBottom: theme.spacing(3),
}));



const SubscriptionMain = () => {

  const primaryColor = '#2563eb'; // Blue
  const secondaryColor = '#6366f1'; // Indigo
  const textPrimaryColor = '#1e293b'; // Slate 800
  const textSecondaryColor = '#64748b'; // Slate 500

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  const handleButtonClick = (plan) => {
    if (!plan.isCurrent) {
      setCurrentPlan(plan);
      setSelectedPlan(plan.name);
      setOpenDialog(true); // Open the dialog after plan selection
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handlePayNow = () => {
    console.log("Proceeding to payment...");
    setOpenDialog(false); // Close the dialog after payment processing
  };

  const handleApplyCoupon = () => {
    console.log("Applying coupon...");
  };
  const featuresList = [
    "Post up to 5 job openings",
    "Access to 20 candidate profiles",
    "Basic analytics",
    "Email notifications for applications",
    "Priority listing for job postings",
    "Customizable email templates",
    "Premium analytics and insights",
  ];

  const plans = [
    {
      name: "Basic",
      price: "Free",
      period: "7 Days",
      description:
        "Ideal for small businesses or startups exploring hiring solutions.",
      isPopular: false,
      features: [true, true, true, true, false, false, false],
      buttonText: "Current plan",
      isCurrent: true,
    },
    {
      name: "Standard",
      price: "$50",
      period: "20 Days",
      description: "Perfect for growing businesses with ongoing hiring needs.",
      isPopular: true,
      features: [true, true, true, true, true, true, false],
      buttonText: "Select plan",
      isCurrent: false,
    },
    {
      name: "Premium",
      price: "$120",
      period: "30 Days",
      description:
        "Designed for enterprises seeking top talent with advanced tools.",
      isPopular: false,
      features: [true, true, true, true, true, true, true],
      buttonText: "Select plan",
      isCurrent: false,
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-r from-[#efd8ff] to-[#c4f6ff] w-full h-full">
      <img src="images/hirree.svg" className="w-48 pl-10 pt-10" alt="Logo" />
      <Container maxWidth="lg">
        <Box className="text-center mb-12">
          <Typography
            variant="h2"
            component="h1"
            className="text-4xl font-bold text-gray-900 mb-4"
          >
            Our Pricing Plans
          </Typography>
          <Typography variant="h6" className="text-gray-600">
            Pick an account plan that fits your workflow
          </Typography>
        </Box>

        <Grid container spacing={4} justifyContent="center">
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.name}>
              <Card
                elevation={plan.isPopular ? 8 : 2}
                onClick={() => handleButtonClick(plan)}
                className={`relative transform transition-all duration-300 cursor-pointer hover:shadow-xl ${
                  selectedPlan === plan.name
                    ? "border-4 border-blue-500"
                    : "border-2 border-gray-200"
                }`}
                style={{ height: "100%" }}
              >
                {plan.isPopular && (
                  <Chip
                    icon={<StarIcon />}
                    label="Popular Choice"
                    color="primary"
                    className="absolute bg-blue-500 text-white"
                  />
                )}

                <CardContent className="p-[32px] mt-6">
                  <Box className="text-center mb-6">
                    <Typography
                      variant="h5"
                      component="h3"
                      className="font-bold text-gray-900 mb-2"
                    >
                      {plan.name}
                    </Typography>
                    <Box className="flex justify-center items-baseline mb-2">
                      <Typography
                        variant="h3"
                        component="span"
                        className="font-extrabold text-blue-600"
                      >
                        {plan.price}
                      </Typography>
                      {plan.period && (
                        <Typography
                          variant="subtitle1"
                          component="span"
                          className="text-gray-500 ml-1"
                        >
                          {plan.period}
                        </Typography>
                      )}
                    </Box>
                    <Typography variant="body2" className="text-gray-600">
                      {plan.description}
                    </Typography>
                  </Box>

                  <Box className="mb-6">
                    {featuresList.map((feature, index) => (
                      <Box key={index} className="flex items-center mb-3">
                        {plan.features[index] ? (
                          <>
                            <CheckIcon
                              className="text-green-500 mr-2"
                              fontSize="small"
                            />
                            <Typography
                              variant="body2"
                              className="text-gray-700"
                            >
                              {feature}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <CloseIcon
                              className="text-red-500 mr-2"
                              fontSize="small"
                            />
                            <Typography
                              variant="body2"
                              className="text-gray-400 line-through"
                            >
                              {feature}
                            </Typography>
                          </>
                        )}
                      </Box>
                    ))}
                  </Box>

                  <Button
                    variant="contained"
                    fullWidth
                    color={plan.isCurrent ? "primary" : "secondary"}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleButtonClick(plan);
                    }}
                  >
                    {plan.buttonText}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>

      {/* Dialog for Summary */}
{/* Dialog for Summary */}

{currentPlan && (
  <StyledDialog
      open={openDialog}
      onClose={handleCloseDialog }
      fullWidth
      maxWidth="sm"
    >

      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SectionCard>
              {/* <Typography variant="h6" sx={{ color: primaryColor, mb: 2, fontWeight: 600 }}>
                Selected Plan Details
              </Typography> */}
              
              <Box sx={{ mb: 3 }}>
                <Typography variant="h4" sx={{ color: textPrimaryColor, fontWeight: 700, mb: 1 }}>
                  {currentPlan.name} Plan
                </Typography>
                <Typography variant="body1" sx={{ color: textSecondaryColor, mb: 2 }}>
                  {currentPlan.description}
                </Typography>
              </Box>

              {/* <Box sx={{ 
                p: 2, 
                bgcolor: 'rgba(245, 158, 11, 0.1)', 
                borderRadius: '8px',
                mb: 3 
              }}>
                <Typography sx={{ color: accentColor, fontWeight: 500 }}>
                  Active Period: 23rd Dec 2024 - 22nd Jan 2025
                </Typography>
              </Box> */}

              <Divider sx={{ my: 3 }} />

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ color: textSecondaryColor }}>
                    Plan Details
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: textSecondaryColor }}>
                    Duration
                  </Typography>
                  <Typography variant="body1" sx={{ color: textPrimaryColor, fontWeight: 500 }}>
                    {currentPlan.period}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body2" sx={{ color: textSecondaryColor }}>
                    Price
                  </Typography>
                  <Typography variant="h6" sx={{ color: primaryColor, fontWeight: 700 }}>
                    {currentPlan.price}
                  </Typography>
                </Grid>
              </Grid>
            </SectionCard>
          </Grid>

          <Grid item xs={12}>
            <SectionCard>
              <Typography variant="h6" sx={{ color: primaryColor, mb: 3, fontWeight: 600 }}>
                Payment Details
              </Typography>

              <Box sx={{ mb: 3 }}>
                <Box sx={{ display: 'flex', gap: 1, mb: 3 }}>
                  <TextField
                    label="Coupon Code"
                    variant="outlined"
                    size="medium"
                    fullWidth
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '8px',
                      }
                    }}
                  />
                  <Button
                    variant="contained"
                    onClick={handleApplyCoupon}
                    sx={{
                      bgcolor: secondaryColor,
                      '&:hover': {
                        bgcolor: '#4f46e5',
                      },
                      borderRadius: '8px',
                      px: 3
                    }}
                  >
                    Apply
                  </Button>
                </Box>

                <Button
                  variant="contained"
                  fullWidth
                  onClick={handlePayNow}
                  sx={{
                    bgcolor: primaryColor,
                    '&:hover': {
                      bgcolor: '#1d4ed8',
                    },
                    borderRadius: '8px',
                    py: 1.5,
                    fontSize: '1.1rem',
                    fontWeight: 600
                  }}
                >
                  Pay {currentPlan.price}
                </Button>
              </Box>

              <Divider sx={{ mb: 2 }} />
              
              <Box sx={{ textAlign: 'center' }}>
                <Typography
                  variant="body2"
                  sx={{
                    color: textSecondaryColor,
                    '& span': {
                      mx: 1,
                      cursor: 'pointer',
                      '&:hover': {
                        color: primaryColor,
                        textDecoration: 'underline',
                      }
                    }
                  }}
                >
                  <span>Terms & Conditions</span>•
                  <span>Privacy Policy</span>•
                  {/* <span>Refund Policy</span> */}
                </Typography>
              </Box>
            </SectionCard>
          </Grid>
        </Grid>
      </DialogContent>
    </StyledDialog>



  )}
    </div>
  );
};

export default SubscriptionMain;
