import React, { useEffect, useState } from 'react'
import { getHiringProgress, MovetoNextStep, RejectApplicants, submitFeedback } from '../../../utils/employer.api/employer.api.services'
import { useApplicantsContext } from '../all Applicants/applicantsContext';
import { useSelector } from 'react-redux';
import { toast, Toaster } from 'react-hot-toast';
import Modal from '@mui/material/Modal';
import { Box } from "@mui/material";

const style = {
    position: 'absolute',
    top: '20px',
    left: '50%',
    transform: 'translate(-50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius: "16px"
};


export default function Hiring({allDetails}) {
    const loginData = useSelector((state) => state.auth.Auth);
    const { applicantsId } = useApplicantsContext();
    const [interviewDetails, setInterviewDetails] = useState([])
    const [openMoveStageModal, setOpenMoveStageModal] = useState(false)
    const [openRejectModal, setOpenRejectModal] = useState(false)
    const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
    const [feedback, setFeedback] = useState([]);


    // Open feedback modal when required
    const handleOpenFeedbackModal = () => {
        setOpenFeedbackModal(true);
    };

    const handleCloseMoveModal = () => {
        setOpenMoveStageModal(false)
        setMoveForm("")
    }
    const handleCloseRjectModal = () => {
        setOpenRejectModal(false); // Corrected state setter function
    }
    const fetchHiringStage = async () => {
        try {
            const response = await getHiringProgress(loginData.access_token, applicantsId)
            console.log("hiring progress", response.data)
            setInterviewDetails(response.data)
            setFeedback(response.data.feedbacks)
        }
        catch (error) {
            console.log(error)
        }
    }
    // if (interviewDetails.stages && interviewDetails.stages.length >= 3) {
    //     console.log("Third last stage name:", interviewDetails.stages[interviewDetails.stages.length - 3].name);
    // } else {
    //     console.log("Stages array is undefined or does not have enough stages.");
    // }

    const [moveForm, setMoveForm] = useState({
        comment: "",
        rating: ""
    });
    const [rejectForm, setRejectForm] = useState({
        comment: "",
    });
    const [feedbackForm, setFeedbackForm] = useState({
        comment: "",
        rating: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setMoveForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleRejectChange = (e) => {
        const { name, value } = e.target;
        setRejectForm(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleFeedbackChange = (e) => {
        const { name, value } = e.target;
        setFeedbackForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const Movepayload = {
        ...moveForm,
        rating: Number(moveForm.rating)
    };
    const RejectPayload = {
        ...rejectForm,
        rating: Number(rejectForm.rating)
    };
    const feedbackPayload = {
        ...feedbackForm,
        rating: Number(feedbackForm.rating),
    };

    const handleMoveToNextStep = async () => {
        try {
            const response = await MovetoNextStep(loginData.access_token, applicantsId, Movepayload);
            if (response.status === 200) {
                toast.dismiss()
                toast.success(response.data.message);
                await fetchHiringStage();
                setTimeout(() => {
                    setOpenMoveStageModal(false);
                }, 1000);
                setMoveForm("");
                interviewDetails();
                allDetails();
            }
        } catch (error) {
            if (error.response && error.response.data && error.response.data.detail) {
                const errorDetails = error.response.data.detail;
                const errorMessage = typeof errorDetails === "string"
                    ? errorDetails
                    : JSON.stringify(errorDetails);
                toast.dismiss()
                toast.error(errorMessage);
            }
            console.error(error);
        }
    };
    
    const handleRejectApplicants = async () => {
        try {
            const response = await RejectApplicants(loginData.access_token, applicantsId, RejectPayload)
            // console.log("Interview scheduled successfully:", response.data);
            if (response.status === 200) {
                toast.dismiss()
                toast.success(response.data.message)
                await fetchHiringStage();
                setTimeout(() => {
                    setOpenRejectModal(false);
                }, 1000);
                setRejectForm("")
            }
        }
        catch (error) {
            const errorDetails = error.response?.data?.detail;

            // if (Array.isArray(errorDetails)) {
            //     // Display each error message in the detail array
            //     errorDetails.forEach((err) => {
            //         toast.error(err.msg || "An error occurred");
            //     });
            // } else {
            //     // Display a generic error message if detail is not an array
            //     toast.error("An unexpected error occurred");
            // }

        }
    }
    const handleSubmitFeedback = async () => {
        try {
            const response = await submitFeedback(loginData.access_token, applicantsId, feedbackPayload);
            if (response.status === 201) {
                toast.dismiss()
                toast.success(response.data.message);
                await fetchHiringStage(); // Fetch updated stages
                setTimeout(() => {
                    setOpenFeedbackModal(false); // Close the modal after successful feedback submission
                }, 1000);
                setFeedbackForm({ comment: "", rating: "" }); // Reset the feedback form
            }
        } catch (error) {
            const errorDetails = error.response?.data?.detail;
            if (Array.isArray(errorDetails)) {
                errorDetails.forEach((err) => {
                    toast.dismiss()
                    toast.error(err.msg || "An error occurred");
                });
            } else {
                toast.dismiss();
                toast.error("An unexpected error occurred");
            }
        }
    };

    useEffect(() => {
        fetchHiringStage()
    }, [applicantsId])

    return (
        <div>
            <div className='hiring-stages'>
                {/* {interviewDetails.stages && interviewDetails.stages.map((stage) => (
                    <div className={`pointer light ${interviewDetails.current_stage ===stage.name ? 'current' :''} `} key={stage.name}>
                        {stage.name}
                    </div>
                ))} */}
                {interviewDetails.stages &&
                    interviewDetails.stages.map((stage) => {
                        const isRejected = interviewDetails.current_stage === "Rejected";
                        const isCurrentStage = interviewDetails.current_stage === stage.name;
                        const isCompleted = stage.order < interviewDetails.stages.find(s => s.name === interviewDetails.current_stage).order;

                        return (
                            <div
                                className={`pointer ${isRejected ? 'grey' : `grey ${isCurrentStage ? 'current' : ''} ${isCompleted ? 'light' : ''}`}`}
                                key={stage.name}
                            >
                                {stage.name}
                            </div>
                        );
                    })}


            </div>
            <div className="design-box mt-3">
                <div className="heading">
                    <h2>Detail </h2>
                </div>
                <div className="box-content">
                    <div className="grid grid-cols-2 gap-2">
                        <div className="box-details">
                            <div className="mb-2">
                                <label>Current Stage </label>
                                <span>{interviewDetails.current_stage}</span>
                            </div>
                            <div className="mb-2">
                                <label>Date </label>
                                <span>{interviewDetails?.interview_details?.interview_date || "No date available"}</span>

                            </div>
                            <div className="mb-2">
                                <label>{interviewDetails?.interview_details?.interview_type} </label>
                                <span>
                                    {interviewDetails?.interview_details?.joining_link ? (
                                        interviewDetails.interview_details.joining_link
                                    ) : interviewDetails?.interview_details?.phone_number ? (
                                        interviewDetails.interview_details.phone_number
                                    ) : interviewDetails?.interview_details?.location ? (
                                        interviewDetails.interview_details.location
                                    ) : (
                                        "No details available"
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="box-details">
                            <div className="mb-2">
                                <label>Assigned to</label>
                                <div className='multiImg mt-5'>
                                    <h5>
                                        {interviewDetails?.interview_details?.interviewer_name}
                                    </h5>
                                    {/* <img src='images/man.svg' className='left-7' />
                                    <img src='images/girl.svg' className='left-0' /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {console.log(interviewDetails.interview_details.status)} */}
                    <div className='flex justify-end'>
                        {interviewDetails?.stages && interviewDetails.stages.length >= 3 && (
                            console.log("Third last stage name:", interviewDetails.stages[interviewDetails.stages.length - 3].name,
                                interviewDetails.current_stage
                            )
                        )}

                        {
                            (interviewDetails?.interview_details?.status === "scheduled" ||
                                (interviewDetails?.current_stage !== "Hired" &&
                                    interviewDetails?.current_stage !== "Rejected" &&
                                    interviewDetails?.stages?.length >= 3 &&
                                    interviewDetails.current_stage === interviewDetails.stages[interviewDetails.stages.length - 3]?.name)) && (
                                <>
                                    <button
                                        type="button"
                                        className="theme-btn bordered-btn"
                                        onClick={() => setOpenMoveStageModal(true)}
                                    >
                                        <span className="color-text">Move to Next Round</span>
                                    </button>
                                    <button
                                        type="button"
                                        className="p-2 border-red-500 rounded-full w-32 text-red-600 ml-2"
                                        style={{ border: "1px solid red" }}
                                        onClick={() => setOpenRejectModal(true)}
                                    >
                                        Reject
                                    </button>
                                </>
                            )
                        }



                    </div>
                </div>
            </div>
            <div className='my-4 justify-between flex items-center'>
                <h2 className='heading2 mb-0'>Feedback</h2>
                <button type="button" className="color-btn " onClick={handleOpenFeedbackModal}><img src="images/icons/plus.svg" className="inline" />Add Feedback</button>
            </div>
            {feedback.map((item) => (
                <div className="interview flex justify-between items-center mt-2 ">
                    <div className="flex items-center">
                        <img src="images/man.svg" className="mr-3" />
                        <div>
                            <h3>{item.interviewer_name}</h3>
                            <h5>{item.comment}</h5>
                        </div>
                    </div>
                    <div>
                        <h5>{item.date}</h5>
                    </div>
                </div>
            ))}

            <Modal
                open={openMoveStageModal}
                onClose={handleCloseMoveModal}
                aria-labelledby="drag-modal-title"
                aria-describedby="drag-modal-description"
            >
                <Box sx={style}>
                    <Toaster />
                    <div className='px-4 pt-5' style={{ borderRadius: "16px 16px 0 0" }}>
                        <h2 className='heading2'>Provide Reason for Moving</h2>
                    </div>
                    <div className='modal-form px-4 py-5'>
                        <form className='form-theme' >
                            <>
                                <div className='relative mb-4'>
                                    <textarea
                                        rows="2"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter feedback for the applicant..."
                                        value={moveForm.comment}
                                        name='comment'
                                        onChange={handleChange}
                                    ></textarea>
                                </div>
                                <div className='relative mb-4'>
                                    <select
                                        name="rating"
                                        value={moveForm.rating}
                                        onChange={handleChange}
                                        className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                        required
                                    >
                                        <option value="">Select rating</option>
                                        {[1, 2, 3, 4, 5].map(num => (
                                            <option key={num} value={num}>{num}</option>
                                        ))}
                                    </select>
                                </div>
                            </>
                            <div className='flex justify-end'>
                                <button type="button" className="theme-btn bordered-btn py-2 mr-3" onClick={handleCloseMoveModal}>
                                    <span className="color-text">Cancel</span>
                                </button>
                                <button type="button" className="theme-btn bordered-btn py-2" onClick={handleMoveToNextStep}>
                                    <span className="color-text" >Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openRejectModal}
                onClose={handleCloseRjectModal}
                aria-labelledby="drag-modal-title"
                aria-describedby="drag-modal-description"
            >
                <Box sx={style}>
                    <Toaster />
                    <div className='px-4 pt-5' style={{ borderRadius: "16px 16px 0 0" }}>
                        <h2 className='heading2'>Provide Reason for Reject</h2>
                    </div>
                    <div className='modal-form px-4 py-5'>
                        <form className='form-theme' >
                            <>
                                <div className='relative mb-4'>
                                    <textarea
                                        rows="2"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        name='comment'
                                        placeholder="Enter feedback for the applicant..."
                                        value={rejectForm.comment}
                                        onChange={handleRejectChange}
                                    ></textarea>
                                </div>
                            </>
                            <div className='flex justify-end'>
                                <button type="button" className="theme-btn bordered-btn py-2 mr-3" onClick={handleCloseRjectModal}>
                                    <span className="color-text">Cancel</span>
                                </button>
                                <button type="button" className="theme-btn bordered-btn py-2" onClick={handleRejectApplicants}>
                                    <span className="color-text" >Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openFeedbackModal}
                onClose={() => setOpenFeedbackModal(false)}
                aria-labelledby="feedback-modal-title"
                aria-describedby="feedback-modal-description"
            >
                <Box sx={style}>
                    <Toaster />
                    <div className='px-4 pt-5' style={{ borderRadius: "16px 16px 0 0" }}>
                        <h2 className='heading2'>Provide Feedback</h2>
                    </div>
                    <div className='modal-form px-4 py-5'>
                        <form className='form-theme'>
                            <>
                                <div className='relative mb-4'>
                                    <textarea
                                        rows="2"
                                        className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                        placeholder="Enter feedback..."
                                        value={feedbackForm.comment}
                                        name='comment'
                                        onChange={handleFeedbackChange}
                                    ></textarea>
                                </div>
                                <div className='relative mb-4'>
                                    <select
                                        name="rating"
                                        value={feedbackForm.rating}
                                        onChange={handleFeedbackChange}
                                        className='block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500'
                                        required
                                    >
                                        <option value="">Select rating</option>
                                        {[1, 2, 3, 4, 5].map((num) => (
                                            <option key={num} value={num}>
                                                {num}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </>
                            <div className='flex justify-end'>
                                <button
                                    type="button"
                                    className="theme-btn bordered-btn py-2 mr-3"
                                    onClick={() => setOpenFeedbackModal(false)} // Close the modal on cancel
                                >
                                    <span className="color-text">Cancel</span>
                                </button>
                                <button
                                    type="button"
                                    className="theme-btn bordered-btn py-2"
                                    onClick={handleSubmitFeedback} // Submit feedback when clicked
                                >
                                    <span className="color-text">Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </Box>
            </Modal>

        </div>

    )
}
