import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSelector} from 'react-redux';
import * as Yup from 'yup';
import { Toaster, toast } from 'react-hot-toast';
import { getCountryColde, getFieldStudy, getLocation, jobList } from "../../../../utils/candidate.api/candidate.api.services";
import { getSkills } from "../../../../utils/candidate.api/candidate.api.services";
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Loader from "../../../loader";
import CloseIcon from '@mui/icons-material/Close';
import config from "../../../../config/config";
// import { setProfileID } from "../../../../redux copy/actionTypes";
// import { json } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const MyProfile = ({ onNext }) => {
    const loginData = useSelector((state) => state.auth.Auth);
    const parsedCvData = useSelector((state) => state.parsedCv.parseCvData?.parsed_data);
    // const CVDetails = useSelector((state) => state.parsedCv.parseCvData?.filename);
    const [totalWorkExperience, settotalWorkExperience] = useState(0);
    const [working, setWorking] = useState(false)
    const [location, setLocation] = useState([])
    const [countryCode, setCountryCode] = useState([])
    const [fieldStudy, setFieldStudy] = useState([])
    const [getskills, setGetSkills] = useState([])
    const [openLoader, setOpenLoader] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false);
    const [job, setJob] = useState([])
    const inputRefs = useRef({});



    const [formData, setFormData] = useState({
        general_details: {
            full_name: '',
            date_of_birth: '',
            gender: '',
            phone: '',
            email: '',
            location: '',
            country_code: '+91'
        },
        education: [{
            college: '',
            degree: '',
            field_of_study: '',
            start_date: '',
            end_date: '',
            grade: '',
        }],
       
        work_experience: [{
            total_work_experience_months: '',
            designation: '',
            company: '',
            location: '',
            start_date: '',
            end_date: '',
            description: '',
            currently_working: false,
        }],
        skills: [],
        portfolio: {
            portfolio_link: '',
            github_link: '',
            other_link: ''
        }
    });

    const [errors, setErrors] = useState({})
    const validationSchema = Yup.object().shape({
        general_details: Yup.object().shape({
            full_name: Yup.string().required('Full Name is required'),
            date_of_birth: Yup.string().matches(
                /^\d{4}-\d{2}-\d{2}$/,
                "Date must be in the format YYYY-MM-DD"
            ).required('DOB is required'),
            gender: Yup.string().required("Gender is required"),
            phone: Yup.string().required("Phone is required").matches(/^[0-9]{10}$/, 'Phone number must be exactly 10 digits'),
            email: Yup.string().email('Invalid email').required("Email is required"),
            // location: Yup.string().required("Location is required"),
            country_code: Yup.string().required("Please select the country code"),
            current_job_role: Yup.string().required("Current job role is required")
        }),
        education: Yup.array().of(
            Yup.object().shape({
                college: Yup.string().required('College name is required'),
                degree: Yup.string().required('Degree is required'),
                field_of_study: Yup.string().required('Field of study required'),
                start_date: Yup.string().matches(
                    /^\d{4}-\d{2}-\d{2}$/,
                    "Date must be in the format YYYY-MM-DD"
                ).required('start date is required'),
                end_date: Yup.string().matches(
                    /^\d{4}-\d{2}-\d{2}$/,
                    "Date must be in the format YYYY-MM-DD"
                ).required('End Date is required'),
                grade: Yup.string().required('Grads is required')
            })
        ),      
        work_experience: Yup.array().of(
            Yup.object().shape({
                designation: Yup.string().when('total_work_experience_months', {
                    is: (value) => value > 0,
                    then: (schema) => schema.required('Designation is required'),
                    otherwise: (schema) => schema.notRequired(),
                }),
                company: Yup.string().when('total_work_experience_months', {
                    is: (value) => value > 0,
                    then: (schema) => schema.required('Company name is required'),
                    otherwise: (schema) => schema.notRequired(),
                }),
                location: Yup.string().when('total_work_experience_months', {
                    is: (value) => value > 0,
                    then: (schema) => schema.required('Location is required'),
                    otherwise: (schema) => schema.notRequired(),
                }),
                start_date: Yup.string().when('total_work_experience_months', {
                    is: (value) => value > 0,
                    then: (schema) => schema.required('Start Date is required'),
                    otherwise: (schema) => schema.notRequired(),
                }),
                currently_working: Yup.boolean(),
                end_date: Yup.string().when(['currently_working', 'total_work_experience_months'], {
                    is: (currentlyWorking, totalWorkExpMonths) => 
                      !currentlyWorking && totalWorkExpMonths > 0,
                    then: (schema) =>
                      schema
                        .required('End Date is required')
                        .test(
                          'is-greater-than-start-date',
                          'End Date must be greater than Start Date',
                          function (value) {
                            if (!value || !this.parent.start_date) {
                              return true; // Skip validation if either date is not set
                            }
                            const endDate = new Date(value);
                            const startDate = new Date(this.parent.start_date);
                            return endDate > startDate; // Ensure end date is greater
                          }
                        ),
                    otherwise: (schema) => schema.notRequired(),
                  }),
                  
                description: Yup.string().when('total_work_experience_months', {
                    is: (value) => value > 0,
                    then: (schema) => schema.required('Description is required'),
                    otherwise: (schema) => schema.notRequired(),
                }),
                
                total_work_experience_months: Yup.number().required('Total work experience is required'),
            })
        ),
        
         skills: Yup.array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required('Skill is required')
                })
            )
            .min(1, 'At least one skill must be selected'),
    });

    useEffect(() => {
        console.log(parsedCvData);
        if (parsedCvData) {
            const phone = parsedCvData.phone || '';
            const formattedPhone = phone.length > 10 ? phone.slice(-10) : phone;
            if (parsedCvData.experience && parsedCvData.experience.length > 0) {
                settotalWorkExperience(parsedCvData.experience[0].total_work_experience_months);
            }
            setFormData({
                general_details: {
                    full_name: parsedCvData.name || '',
                    phone: formattedPhone || '',
                    email: parsedCvData.email || '',
                    // location: parsedCvData.location || '', 
                    date_of_birth: '',
                    gender: parsedCvData.gender || '',
                    country_code: '+91',
                    current_job_role:parsedCvData.current_job_role || ''
                },
                education: parsedCvData.education && parsedCvData.education.length > 0 ?
                    parsedCvData.education.map(edu => ({
                        college: edu.institution || '',
                        degree: edu.degree || '',
                        field_of_study: edu.field_of_study || '',
                        start_date: edu.start_date || '',
                        end_date: edu.end_date || '',
                        grade: edu.cgpa || ''
                    })) : [{ college: '', degree: '', field_of_study: '', start_date: '', end_date: '', grade: '' }],
               
                work_experience: parsedCvData.experience && parsedCvData.experience.length > 0 ?
                    parsedCvData.experience.map(work => ({
                        total_work_experience_months:work.total_work_experience_months|| 0,
                        designation: work.title || '',
                        company: work.company || '',
                        location: work.location || '',
                        start_date: work.start_date || '',
                        end_date: work.end_date || '',
                        description: '',
                        currently_working: working,

                    })) : [],
//{ total_work_experience_months: 0, designation: '', company: '', location: '', start_date: '', end_date: '', description: '', currently_working: false }
                skills: [],
                //parsedCvData.skills ? parsedCvData.skills.flatMap(skillCategory =>
                //     skillCategory.skills.map(skill => ({ name: skill }))
                // ) : [{ name: '' }],
                portfolio: {
                    portfolio_link: parsedCvData.additional_info.portfolio_link || '',
                    github_link: parsedCvData.additional_info.github_link || '',
                    other_link: Object.keys(parsedCvData.additional_info).find(key => !['portfolio', 'github'].includes(key)) 
                        ? parsedCvData.additional_info[Object.keys(parsedCvData.additional_info).find(key => !['portfolio', 'github'].includes(key))] 
                        : ''
                }
                
            });

        }
    }, [parsedCvData]);


    const fetchJobs = async () => {
        try {
            let response = await jobList()
            setJob(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }

    const fetchLocation = async () => {
        try {
            let response = await getLocation()
            setLocation(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }


    const fetchCountryCode = async () => {
        try {
            let response = await getCountryColde()
            setCountryCode(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    const fetchFieldStudy = async () => {
        try {
            let response = await getFieldStudy()
            setFieldStudy(response.data)
        }
        catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchLocation()
        fetchCountryCode()
        fetchFieldStudy()
        // fetchCurrentJob()
        fetchJobs()
        fetchLocation()
        //fetchProfileDetails()
    }, [])

    const handleChange = async(e) => {
        const { name, value } = e.target;
        console.log("value", value);

        if (name === "work_experience.total_work_experience_months.0") { 
            const totalExperience = value === "" ? "" : parseInt(value, 10) || 0;
            settotalWorkExperience(totalExperience);
            setFormData(prevData => {
                let updatedWorkExperience;
                if (totalExperience > 0) {
                    if (parsedCvData && parsedCvData.experience && parsedCvData.experience.length > 0) {
                        // Populate with parsed CV data if available
                        updatedWorkExperience = parsedCvData.experience.map(work => ({
                            total_work_experience_months: totalExperience,
                            designation: work.title || "",
                            company: work.company || "",
                            location: work.location || "",
                            start_date: work.start_date || "",
                            end_date:work.end_date||"",
                            description: work.description || '',
                            currently_working: work.currently_working || false,
                        }));
                        
                    } else {
                        // Provide empty fields if parsed data is not available
                        updatedWorkExperience = [{
                            total_work_experience_months: totalExperience,
                            designation: '',
                            company: '',
                            location: '',
                            start_date: '',
                            end_date: '',
                            description: '',
                            currently_working: false,
                        }];
                    }
                } else {
                    //Set all fields to empty if total experience is 0
                    updatedWorkExperience = [{
                        total_work_experience_months: totalExperience,
                        designation: '',
                        company: '',
                        location: '',
                        start_date: '',
                        end_date: '',
                        description: '',
                        currently_working: false,
                    }];
                    // updatedWorkExperience = [{
                    //     total_work_experience_months: totalExperience
                    // }];
                }
                return {
                    ...prevData,
                    //total_work_experience_months: totalExperience,
                    work_experience: updatedWorkExperience,
                };
            });

        }
        else if (name === "skills") {
            setFormData(prevData => ({
                ...prevData,
                skills: value.map(skillName => ({ name: skillName }))  
            }));
        }
        else {

            const [section, field, index] = name.split('.');

            console.log(section,field,index);
            if (index !== undefined) {
                setFormData(prevData => {
                    const updatedState = {
                        ...prevData,
                        [section]: prevData[section].map((item, i) => {
                        
                            return i === parseInt(index) ? { ...item, [field]: value } : item;
                        })
                    };
                    console.log(JSON.stringify(updatedState,null,2));
                    return updatedState;
                });
               
            } else {
                setFormData(prevData => {
                    const updatedState = {
                        ...prevData,
                        [section]: {
                            ...prevData[section],
                            [field]: value
                        }
                    };
                   // console.log(JSON.stringify(updatedState));
                    return updatedState;
                });
            }
        }
       
        try {
            let [section, field, index] = name.split('.');
            console.log(section, field, index);
            if (index !== undefined) {
                await validationSchema.validateAt(`${section}[${index}].${field}`, {
                    [section]: formData[section].map((item, i) =>
                        i === parseInt(index) ? { ...item, [field]: value } : item
                    )
                });
                setErrors(prevErrors => ({ ...prevErrors, [`${section}[${index}].${field}`]: undefined }));
            }
            else if(name==='skills'){
                await validationSchema.validateAt(name, {
                    [section]: value.map(skillName => ({ name: skillName })),
                    
                });
                setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));

            }
            else {
                
                await validationSchema.validateAt(name, {
                    [section]: {
                        ...formData[section], [field]: value
                    }
                });
                setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
            }
            
        } catch (error) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: error.message }));
            // console.log(error);
        }
    };
    const handleAddEducation = () => {
        setFormData(prevData => ({
            ...prevData,
            education: [
                ...prevData.education,
                { college: '', degree: '', field_of_study: '', start_date: '', end_date: '', grade: '' }
            ]
        }));
    };
    const handleAddWorkExperience = () => {
        //console.log("totalWorkExperience", totalWorkExperience);
        setFormData(prevData => ({
            ...prevData,
            work_experience: [
                ...prevData.work_experience,
                {
                    total_work_experience_months:totalWorkExperience,
                    designation: '',
                    company: '',
                    location: '',
                    start_date: '',
                    end_date: '',
                    description: '',
                    currently_working: false,
                }
            ]
        }));
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formattedData = formatForSubmission();
       console.log("work experience",formattedData["work_experience"]);
        try {
            await validationSchema.validate(formattedData, { abortEarly: false });
            const method = isUpdating ? 'PUT' : 'POST';
            
            const url = `${config.apiUrl}candidate/profile`;
            const response = await fetch(url, {
                method: method,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginData.access_token}`
                },
                body: JSON.stringify(formattedData)
            });
            console.log(method, response.status)
            if ((method === 'POST' && response.status === 201) || (method === 'PUT' && response.status === 200)) {
                setOpenLoader(true);
                const data = await response.json();
                toast.dismiss();
                toast.success(data.message);
                console.log('Success:', data);
                fetchProfileData()
            }
        }

        catch (error) {
            console.log(error)
            if (error.name === 'ValidationError') {
                const validationErrors = {};
                error.inner.forEach(err => {
                    validationErrors[err.path] = err.message;
                });
                console.log(JSON.stringify(validationErrors, null, 2));
                setErrors(validationErrors);
                const firstError = Object.keys(validationErrors)[0];
                // console.log("firstError", firstError);
                // console.log("inputRefs", inputRefs.current);
                if (inputRefs.current[firstError]) {
                    inputRefs.current[firstError].scrollIntoView({ behavior: 'smooth', block: 'center' });
                }
                // toast.error("Empty fields")
            }
            else {
                console.log(JSON.stringify(error));
                //console.error("Error response:", error.response ? error.response.data : error);
                if (error.response && error.response.data && Array.isArray(error.response.data.detail)) {
                    const validationErrors = {};
                    error.response.data.detail.forEach(err => {
                        const field = err.loc[1];
                        validationErrors[field] = err.msg;
                    });
                    setErrors(validationErrors);
                }
            }


        }
        finally {
            setOpenLoader(false)
        }
    };
    // Function to format data for API submission
    const formatForSubmission = () => {
        console.log(formData.skills);
        return {
            general_details: {
                full_name: formData.general_details.full_name,
                date_of_birth: formData.general_details.date_of_birth || '',
                gender: formData.general_details.gender || '',
                phone: formData.general_details.phone || '',
                email: formData.general_details.email || '',
                // location: formData.general_details.location || '',
                country_code: formData.general_details.country_code || '',
                current_job_role:formData.general_details.current_job_role || ''
            },
            education: formData.education.map(edu => ({
                college: edu.college || '',
                degree: edu.degree || '',
                field_of_study: edu.field_of_study || '',
                start_date: edu.start_date || '',
                end_date: edu.end_date || '',
                grade: edu.grade || ''
            })),
            work_experience:formData.work_experience.length>0?formData.work_experience.map(work => ({
                total_work_experience_months: work.total_work_experience_months || 0,
                designation: work.designation || '',
                company: work.company || '',
                location: work.location || '',
                start_date: work.start_date || '',
                end_date: work.end_date || '',
                description: work.description || '',
                currently_working: work.currently_working
            })) : [{
                total_work_experience_months: 0,
                designation:'',
                company: '',
                location: '',
                start_date: '',
                end_date:'',
                description: '',
                currently_working:false
            }],
            skills: formData.skills.map(skill => ({
                name: skill.name || ''
            })),
            portfolio: {
                portfolio_link: formData.portfolio.portfolio_link || '',
                github_link: formData.portfolio.github_link || '',
                other_link: formData.portfolio.other_link || ''
            }
        };
    };

    useEffect(() => {
        const fetchSkills = async () => {
            try {
                let response = await getSkills();
                const fetchedSkills = response.data.map(skill => skill.toLowerCase()); // Convert fetched skills to lowercase
                setGetSkills(fetchedSkills);
                const formattedSkills = fetchedSkills.map(skill => ({
                    name: skill,
                    existsInParsedCv: parsedCvData.skills
                        ? parsedCvData.skills.some(skillCategory =>
                            skillCategory.skills.some(parsedSkill => parsedSkill.toLowerCase() === skill)
                        )
                        : false,
                }));

                setFormData(prevData => ({
                    ...prevData,
                    skills: formattedSkills.filter(skill => skill.existsInParsedCv),
                }));
            } catch (error) {
                console.log(error);
            }
        };

        fetchSkills();
    }, [parsedCvData]);
    const handleRemoveEducation = (index) => {
        const updatedEducation = [...formData.education];
        updatedEducation.splice(index, 1);
        setFormData({
            ...formData,
            education: updatedEducation,
        });
    };
    const handleRemoveExperience = (index) => {
        const updateExperience = [...formData.work_experience];
        updateExperience.splice(index, 1);
        setFormData({
            ...formData,
            work_experience: updateExperience,
        });
    };
    const handleWorkingChange = (index, checked) => {
        setFormData(prevData => ({
            ...prevData,
            work_experience: prevData.work_experience.map((work, i) =>
                i === index
                    ? { ...work, currently_working: checked, end_date: checked ? '' : work.end_date } // Clear end_date if currently working is checked
                    : work
            )
        }));
    };
    const fetchProfileData = async () => {
        try {
            const res = await axios.get(`${config.apiUrl}candidate/profile`, {
                headers: {
                    'Authorization': `Bearer ${loginData.access_token}`,
                }
            });
            console.log(res.data)
            if (res.status === 200) {
                const profileData = res.data;
                console.log(profileData)
                settotalWorkExperience(profileData.work_experience && profileData.work_experience.length ? profileData.work_experience[0].total_work_experience_months : 0);
                setFormData({
                    general_details: {
                        full_name: profileData.general_details.full_name || '',
                        phone: profileData.general_details.phone || '',
                        email: profileData.general_details.email || '',
                        location: profileData.general_details.location || '',
                        date_of_birth: profileData.general_details.date_of_birth || '',
                        gender: profileData.general_details.gender || '',
                        country_code: profileData.general_details.country_code || '+91',
                    },
                    education: profileData.education && profileData.education.length > 0 ?
                        profileData.education.map(edu => ({
                            college: edu.college || '',
                            degree: edu.degree || '',
                            field_of_study: edu.field_of_study || '',
                            start_date: edu.start_date || '',
                            end_date: edu.end_date || '',
                            grade: edu.grade || '',
                        })) : [{ college: '', degree: '', field_of_study: '', start_date: '', end_date: '', grade: '' }],

                    work_experience: profileData.work_experience && profileData.work_experience.length > 0 ?
                        profileData.work_experience.map(work => ({
                            total_work_experience_months: work.total_work_experience_months == 0 ? 0 : work.total_work_experience_months || 0,
                            designation: work.designation || '',
                            company: work.company || '',
                            location: work.location || '',
                            start_date: work.start_date || '',
                            end_date: work.end_date || '',
                            description: work.description || '',
                            currently_working: work.currently_working || false,
                        })) : [{
                            total_work_experience_months: 0,
                            designation: '',
                            company: '',
                            location: '',
                            start_date: '',
                            end_date: '',
                            description: '',
                            currently_working: false,
                        }],

                    skills: profileData.skills && profileData.skills.length > 0 ?
                        profileData.skills.map(skill => ({
                            name: skill.name || '',
                        })) : [{ name: '' }],

                    portfolio: {
                        portfolio_link: profileData.portfolio.portfolio_link || '',
                        github_link: profileData.portfolio.github_link || '',
                        other_link: profileData.portfolio.other_link || ''
                    }
                });
                setIsUpdating(true)
            }
        } catch (error) {
            console.error('Error fetching profile data:', error);
        }
    };
    useEffect(() => {
      

        fetchProfileData();
    }, []);

    return (
        <>
            <Toaster />
            <Loader openLoader={openLoader} />
            <div>
                <h3 className="sub-heading">General Details</h3>

                <form className="mt-5 form-theme">
                    <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
                        <div>
                            <label for="first_name" className="block mb-2">Full Name<span className="text-red-500">*</span></label>
                            <input type="text" id="first_name"
                                className={`w-full border ${errors && errors[`general_details.full_name`] ? 'border-red-500' : 'border-gray-300'}`} placeholder="Full name"
                                name="general_details.full_name"
                                value={formData.general_details.full_name}
                                onChange={handleChange}
                                ref={(el) => (inputRefs.current['general_details.full_name'] = el)} 
                            />
                            {errors["general_details.full_name"] && <span className="text-red-500 text-sm">{errors["general_details.full_name"]
                            }</span>}
                        </div>
                        <div>
                            <label for="last_name" className="block mb-2">Date of Birth<span className="text-red-500">*</span></label>
                            <input type="date" id="last_name"
                                className={`w-full border ${errors && errors[`general_details.date_of_birth`] ? 'border-red-500' : 'border-gray-300'}`} placeholder="YYYY-MM-DD"
                                name="general_details.date_of_birth"
                                value={formData.general_details.date_of_birth}
                                onChange={handleChange}
                                ref={(el) => (inputRefs.current['general_details.date_of_birth'] = el)}
                                onFocus={(e) => e.target.showPicker && e.target.showPicker()}
                                // max={new Date()}
                            />
                            {errors["general_details.date_of_birth"] && <span className="text-red-500 text-sm">{errors["general_details.date_of_birth"]}</span>}
                        </div>
                        <div>
                            <label for="company" className="block mb-2">Gender<span className="text-red-500">*</span></label>
                            <select className={`w-full border ${errors && errors[`general_details.gender`] ? 'border-red-500' : 'border-gray-300'}`}
                                value={formData.general_details.gender}
                                onChange={handleChange}
                                name="general_details.gender"
                                ref={(el) => (inputRefs.current['general_details.gender'] = el)}
                            >
                                <option value="" disabled hidden>Gender</option>
                                <option value="male">male</option>
                                <option value="female">female</option>
                                <option value="non-binary">non-binary</option>
                                <option value="prefer not to say">prefer not to say</option>

                            </select>
                            {errors["general_details.gender"] && <span className="text-red-500 text-sm">{errors["general_details.gender"]}</span>}

                        </div>
                        <div>
                            <label for="phone" className="block mb-2">Phone number<span className="text-red-500">*</span></label>
                            <div className="relative flex">
                                <select
                                    name="general_details.country_code"
                                    className={`border ${errors && errors[`general_details.country_code`] ? 'border-red-500' : 'border-gray-300'} w-20 bg-gray-200 `}
                                    value={formData.general_details.country_code}
                                    onChange={handleChange}
                                    style={{ borderRadius: "5px 0 0 5px" }}
                                    ref={(el) => (inputRefs.current['general_details.country_code'] = el)}
                                >
                                    <option value="+91" className="text-center text-gray-100"><span className="text-gray-100">+91</span></option>
                                    {countryCode.map((items) => (
                                        <option key={items} className="text-center" value={items}>{items}</option>
                                    ))}
                                </select>
                                <input type="tel" id="phone" className={`w-full border ${errors && errors[`general_details.phone`] ? 'border-red-500' : 'border-gray-300'}`} placeholder="123-45-678" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                    name="general_details.phone"
                                    value={formData.general_details.phone}
                                    onChange={handleChange}
                                    style={{ borderRadius: "0 5px 5px 0" }}
                                    ref={(el) => (inputRefs.current['general_details.phone'] = el)}
                                />
                            </div>
                            {errors["general_details.country_code"] && <span className="text-red-500 text-sm">{errors["general_details.country_code"]}</span>}
                            {errors["general_details.phone"] && <span className="text-red-500 text-sm">{errors["general_details.phone"]}</span>}
                        </div>
                        <div>
                            <label for="website" className="block mb-2">Email Id<span className="text-red-500">*</span></label>
                            <input type="url" id="website"
                                className={`w-full border ${errors && errors[`general_details.email`] ? 'border-red-500' : 'border-gray-300'}`} placeholder="exaple@gmail.com"
                                name="general_details.email"
                                value={formData.general_details.email}
                                ref={(el) => (inputRefs.current['general_details.email'] = el)}
                                onChange={handleChange} />
                            {errors["general_details.email"] && <span className="text-red-500 text-sm">{errors["general_details.email"]}</span>}
                        </div>
                        
                        <div>
                            <label for="visitors" className="block mb-2">Current Job Role<span className="text-red-500">*</span></label>
                            <select
                                name="general_details.current_job_role"
                                className={`w-full border ${errors && errors[`general_details.current_job_role`] ? 'border-red-500' : 'border-gray-300'}`}
                                value={formData.general_details.current_job_role}
                                onChange={handleChange}
                                ref={(el) => (inputRefs.current['general_details.current_job_role'] = el)}
                            >
                                <option value="" disabled hidden>Select current job role</option>
                                {job.map((items) => (
                                    <option key={items} value={items}>{items}</option>
                                ))}
                            </select>
                            {errors["general_details.current_job_role"] && <span className="text-red-500 ">{errors["general_details.current_job_role"]}</span>}
                        </div>
                    </div>

                </form>
            </div>
            <div className="pt-5">
                <h3 className="sub-heading">Education</h3>
                {/* ////Education////////////////////////// */}
                <form className="mt-5 form-theme" onSubmit={handleSubmit}>
                    {/* <label htmlFor="education" className="block mb-5">Education</label> */}
                    {formData.education.map((edu, index) => (
                        <React.Fragment key={index}>
                            {formData.education.length > 1 && (
                                <CloseIcon onClick={() => handleRemoveEducation(index)} className="absolute right-0 cursor-pointer" />
                            )}
                            <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
                                <div>
                                    <label htmlFor={`college_${index}`} className="block mb-2">Institute Name<span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        id={`college_${index}`}
                                        className={`w-full border ${errors && errors[`education[${index}].college`] ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="e.g. Hindu College"
                                        name={`education.college.${index}`}
                                        value={edu.college}
                                        onChange={handleChange}
                                        ref={(el) => (inputRefs.current[`education[${index}].college`] = el)}
                                    />

                                    {(errors && errors[`education[${index}].college`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].college`]}</span>
                                    ) : null}

                                </div>
                                <div>
                                    <label htmlFor={`degree_${index}`} className="block mb-2">Degree<span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        id={`degree_${index}`}
                                        className={`w-full border ${errors && errors[`education[${index}].degree`] ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="e.g. Bachelor’s of Architecture"
                                        name={`education.degree.${index}`}
                                        value={edu.degree}
                                        onChange={handleChange}
                                        ref={(el) => (inputRefs.current[`education[${index}].degree`] = el)}
                                    />
                                    {(errors && errors[`education[${index}].degree`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].degree`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`field_of_study_${index}`} className="block mb-2">Field of Study<span className="text-red-500">*</span></label>
                                    <select
                                        id={`field_of_study_${index}`}
                                        name={`education.field_of_study.${index}`}
                                        className={`w-full border ${errors && errors[`education[${index}].field_of_study`] ? 'border-red-500' : 'border-gray-300'}`}
                                        value={edu.field_of_study}
                                        onChange={handleChange}
                                        ref={(el) => (inputRefs.current[`education[${index}].field_of_study`] = el)}
                                    >
                                        <option value="" disabled hidden>e.g. Architecture</option>
                                        {fieldStudy.map((items) => (
                                            <option key={items} value={items}>{items}</option>
                                        ))}
                                    </select>
                                    {(errors && errors[`education[${index}].field_of_study`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].field_of_study`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`start_date_${index}`} className="block mb-2">Start Date<span className="text-red-500">*</span></label>
                                    <input
                                        type="date"
                                        id={`start_date_${index}`}
                                        className={`w-full border ${errors && errors[`education[${index}].start_date`] ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="YYYY-MM-DD"
                                        name={`education.start_date.${index}`}
                                        value={edu.start_date}
                                        onChange={handleChange}
                                        ref={(el) => (inputRefs.current[`education[${index}].start_date`] = el)}
                                        onFocus={(e) => e.target.showPicker && e.target.showPicker()}
                                    />
                                    {(errors && errors[`education[${index}].start_date`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].start_date`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`end_date_${index}`} className="block mb-2">End Date<span className="text-red-500">*</span></label>
                                    <input
                                        type="date"
                                        id={`end_date_${index}`}
                                        className={`w-full border ${errors && errors[`education[${index}].end_date`] ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="YYYY-MM-DD"
                                        name={`education.end_date.${index}`}
                                        value={edu.end_date}
                                        onChange={handleChange}
                                        ref={(el) => (inputRefs.current[`education[${index}].end_date`] = el)}
                                        onFocus={(e) => e.target.showPicker && e.target.showPicker()}
                                    />
                                    {(errors && errors[`education[${index}].end_date`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].end_date`]}</span>
                                    ) : null}
                                </div>
                                <div>
                                    <label htmlFor={`grade_${index}`} className="block mb-2">Grade<span className="text-red-500">*</span></label>
                                    <input
                                        type="text"
                                        id={`grade_${index}`}
                                        className={`w-full border ${errors && errors[`education[${index}].grade`] ? 'border-red-500' : 'border-gray-300'}`}
                                        placeholder="Grade / CGPA"
                                        name={`education.grade.${index}`}
                                        value={edu.grade}
                                        onChange={handleChange}
                                        ref={(el) => (inputRefs.current[`education[${index}].grade`] = el)}
                                    />
                                    {(errors && errors[`education[${index}].grade`]) ? (
                                        <span className="text-red-500 text-sm">{errors[`education[${index}].grade`]}</span>
                                    ) : null}
                                </div>
                            </div>
                            <hr className="mb-4" />
                        </React.Fragment>
                    ))}
                    <button
                        type="button"
                        className="theme-btn bordered-btn py-2.5"
                        onClick={handleAddEducation}
                    >
                        <span className="color-text">+ Add Education</span>
                    </button>
                </form>
                {/* ////work experience/////////////////// */}
                <form className="mt-5 form-theme">
                    <h3 className="sub-heading mb-2">Work Experience</h3>
                    <div className="mb-3" style={{ width: "200px" }}>
                        <label className="block mb-2">Total work experience<span className="text-red-500">*</span></label>
                        {console.log(formData.work_experience[0], "?")}
                        <input
                            type="text"
                            className={`w-full border ${errors && errors[`work_experience[0].total_work_experience_months`] ? 'border-red-500' : 'border-gray-300'}`}
                            placeholder="In months"
                            name="work_experience.total_work_experience_months.0"
                            value={formData.work_experience[0]?.total_work_experience_months}
                            onChange={handleChange}
                            ref={(el) => (inputRefs.current[`work_experience[0].total_work_experience_months`] = el)}
                        />
                        {(errors && errors[`work_experience[0].total_work_experience_months`]) ? (
                            <span className="text-red-500 text-sm">{errors[`work_experience[0].total_work_experience_months`]}</span>
                        ) : null}
                    </div>
                    {parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && (
                        <React.Fragment>
                            {formData.work_experience.map((work, index) => (
                                <div key={index}>
                                    {formData.work_experience.length > 1 && (
                                        <CloseIcon onClick={() => handleRemoveExperience(index)} className="absolute right-0 cursor-pointer" />
                                    )}
                                    <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
                                        <div>
                                            <label htmlFor={`designation_${index}`} className="block mb-2">Designation<span className="text-red-500">*</span></label>
                                            <input
                                                type="text"
                                                className={`w-full border ${errors && errors[`work_experience[${index}].designation`] ? 'border-red-500' : 'border-gray-300'}`}
                                                placeholder="e.g. UI/UX Designer"
                                                name={`work_experience.designation.${index}`}
                                                value={work.designation}
                                                onChange={handleChange}
                                                ref={(el) => (inputRefs.current[`work_experience[${index}].designation`] = el)}
                                            />
                                            {( parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].designation`]) ? (
                                                <span className="text-red-500 text-sm">{errors[`work_experience[${index}].designation`]}</span>
                                            ) : null} 

                                        </div>
                                        <div>
                                            <label htmlFor={`company_${index}`} className="block mb-2">Company<span className="text-red-500">*</span></label>
                                            <input
                                                type="text"
                                                className={`w-full border ${errors && errors[`work_experience[${index}].company`] ? 'border-red-500' : 'border-gray-300'}`}
                                                placeholder="e.g. Google"
                                                name={`work_experience.company.${index}`}
                                                value={work.company}
                                                onChange={handleChange}
                                                ref={(el) => (inputRefs.current[`work_experience[${index}].company`] = el)}
                                            />
                                            {(parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].company`]) ? (
                                                <span className="text-red-500 text-sm">{errors[`work_experience[${index}].company`]}</span>
                                            ) : null}
                                        </div>
                                        <div>
                                            <label htmlFor={`location_${index}`} className="block mb-2">Location<span className="text-red-500">*</span></label>
                                            <select
                                                name={`work_experience.location.${index}`}
                                                className={`w-full border ${errors && errors[`work_experience[${index}].location`] ? 'border-red-500' : 'border-gray-300'}`}
                                                value={work.location}
                                                onChange={handleChange}
                                                ref={(el) => (inputRefs.current[`work_experience[${index}].location`] = el)}
                                            >
                                                <option value="" disabled hidden>Location</option>
                                                {location.map((items) => (
                                                    <option key={items} value={items}>{items}</option>
                                                ))}
                                            </select>
                                            {(parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].location`]) ? (
                                                <span className="text-red-500 text-sm">{errors[`work_experience[${index}].location`]}</span>
                                            ) : null}
                                        </div>
                                        <div>
                                            <label htmlFor={`start_date_${index}`} className="block mb-2">Start Date<span className="text-red-500">*</span></label>
                                            <input
                                                type="date"
                                                id={`start_date_${index}`}
                                                className={`w-full border ${errors && errors[`work_experience[${index}].start_date`] ? 'border-red-500' : 'border-gray-300'}`}
                                                placeholder="YYYY-MM-DD"
                                                name={`work_experience.start_date.${index}`}
                                                value={work.start_date}
                                                onChange={handleChange}
                                                ref={(el) => (inputRefs.current[`work_experience[${index}].start_date`] = el)}
                                                onFocus={(e) => e.target.showPicker && e.target.showPicker()}
                                            />
                                            {(parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].start_date`]) ? (
                                                <span className="text-red-500 text-sm">{errors[`work_experience[${index}].start_date`]}</span>
                                            ) : null}
                                        </div>
                                        {!work.currently_working && (
                                            <div>
                                                <label htmlFor={`end_date_${index}`} className="block mb-2">End Date<span className="text-red-500">*</span></label>
                                                <input
                                                    type="date"
                                                    id={`end_date_${index}`}
                                                    className={`w-full border ${errors && errors[`work_experience[${index}].end_date`] ? 'border-red-500' : 'border-gray-300'}`}
                                                    placeholder="YYYY-MM-DD"
                                                    name={`work_experience.end_date.${index}`}
                                                    value={work.end_date}
                                                    ref={(el) => (inputRefs.current[`work_experience[${index}].end_date`] = el)}
                                                    onChange={handleChange}
                                                    onFocus={(e) => e.target.showPicker && e.target.showPicker()}
                                                />
                                                {(parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].end_date`]) ? (
                                                    <span className="text-red-500 text-sm">{errors[`work_experience[${index}].end_date`]}</span>
                                                ) : null}
                                            </div>
                                        )}
                                        <div>
                                            <label htmlFor={`description_${index}`} className="block mb-2">Description<span className="text-red-500">*</span></label>
                                            <input
                                                type="text"
                                                id={`description_${index}`}
                                                className={`w-full border ${errors && errors[`work_experience[${index}].description`] ? 'border-red-500' : 'border-gray-300'}`}
                                                placeholder="Description"
                                                name={`work_experience.description.${index}`}
                                                value={work.description}
                                                ref={(el) => (inputRefs.current[`work_experience[${index}`] = el)}
                                                onChange={handleChange}
                                            />
                                            {(parseInt(formData.work_experience[0]?.total_work_experience_months, 10) > 0 && errors && errors[`work_experience[${index}].description`]) ? (
                                                <span className="text-red-500 text-sm">{errors[`work_experience[${index}].description`]}</span>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="flex items-center mb-5">
                                        <input
                                            type="checkbox"
                                            id={`currently_working_${index}`}
                                            name={`work_experience.currently_working.${index}`}
                                            checked={work.currently_working}
                                            onChange={(e) => handleWorkingChange(index, e.target.checked)}
                                        />
                                        <label htmlFor={`currently_working_${index}`} className="ml-2">Currently Working<span className="text-red-500">*</span></label>
                                    </div>
                                </div>
                            ))}
                            <button
                                type="button"
                                className="theme-btn bordered-btn py-2.5"
                                onClick={handleAddWorkExperience}
                            >
                                <span className="color-text">+ Add Work Experience</span>
                            </button>
                        </React.Fragment>
                    )}

                </form>

                {/* /////////skills/////////// */}
                <form className="mt-5 form-theme">
                    <label htmlFor="skills" className="block mb-2">Skills<span className="text-red-500">*</span></label>
                    <div>

                        <Select
                            multiple={true}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            className="w-96 border border-gray mb-2"
                            name="skills"
                            value={formData.skills.map(skill => skill.name)}
                            onChange={(e) => handleChange({ target: { name: "skills", value: e.target.value } })}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                            renderValue={(selected) => (

                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>

                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                            MenuProps={MenuProps}
                        >
                            {getskills.map((item) => (
                                <MenuItem key={item} value={item}>
                                    {item}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>
                    {errors.skills && (
                        <span className="text-red-500 text-sm">{errors.skills}</span>
                    )}
                </form>
                {/* ///////////////Links/////////////// */}
                <form className="mt-5 form-theme">
                   <h3 className="sub-heading mb-2">Work samples</h3>
                    {/* <label for="first_name" className="block mb-2">Work samples</label> */}
                    <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
                        <div>
                            <label for="first_name" className="block mb-2">GitHub link</label>
                            <input type="text" id="first_name" className="w-full border border-gray-300" placeholder="GitHub link"
                                name="portfolio.portfolio_link"
                                value={formData.portfolio.github_link}
                                onChange={handleChange} />
                        </div>
                        <div>
                            <label for="first_name" className="block mb-2">Portfolio link</label>
                            <input type="text" id="first_name" className="w-full border border-gray-300" placeholder="Portfolio Link"
                                name="portfolio.github_link"
                                value={formData.portfolio.portfolio_link}
                                onChange={handleChange} />
                        </div>
                        <div>
                            <label for="first_name" className="block mb-2">Other Work Link</label>
                            <input type="text" id="first_name" className="w-full border border-gray-300" placeholder="Other Links"
                                name="portfolio.other_link"
                                value={formData.portfolio.other_link}
                                onChange={handleChange} />
                        </div>
                    </div>
                </form>
                {/* <button type="button" className="theme-btn bordered-btn py-2 mr-2"><span className="color-text ">Save</span></button> */}
                <button type="button" className="theme-btn bordered-btn py-2" onClick={(e) => (handleSubmit(e))}>
                    <span className="color-text ">       {isUpdating ? 'Update' : 'Submit'}</span>
                </button>

            </div>
        </>
    )
}
export default MyProfile;