import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { Menu, MenuItem, IconButton } from "@mui/material";
import { useSelector } from "react-redux";
import config from "../../../config/config";
import axios from "axios";
import { toast } from "react-hot-toast";

function Interviewer() {
  const [open, setOpen] = useState(false);
  const [interviewers, setInterviewers] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [warning, setWarning] = useState(false);
  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;
  const BASE_URL = config.apiUrl;
  const [triggerFetch, setTriggerFetch] = useState(false);
  const [selectedInterviewer, setSelectedInterviewer] = useState(null);
  const [openEdit, setOpenEdit] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currIndex, setCurrIndex] = useState(null);
  const [currDelete, setCurrDelete] = useState(null);
  const openA = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseA = () => {
    setCurrIndex(null);
    setAnchorEl(null);
  };

  const handleClose = () => setOpen(false);

  const handleWarning = (index,currIndex) => {
    console.log("Index:", index, currIndex);
    console.log("Interviewers:", interviewers[index]);
    setCurrDelete(interviewers[index]);
    setSelectedInterviewer(interviewers[currIndex]);
    console.log("Selected Interviewer:", selectedInterviewer, currDelete);

    const deleteData = {
      interviewer_ids: [currDelete.id],
    };

    try {
      const response =  axios.delete(
        `${BASE_URL}employer/interviewers-list/delete`,
        {
          data: deleteData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("Delete response:", response);
      if (response.status === 200) {
        toast.dismiss()
        toast.success("Interviewer deleted successfully");
        setTriggerFetch((prev) => !prev); // Toggle trigger to re-run useEffect
        setWarning(false);
      }
    } catch (error) {
      console.error("Error during delete:", error);
    }

    setWarning(!warning);
  };

  const handleEdit = () => {
    const interviewer = interviewers[currIndex];
    console.log("edit", interviewer);
    setSelectedInterviewer(interviewer);
    setOpenEdit(true);
  };

  const handleSaveEdit = async () => {
    try {
      await axios.put(
        `${BASE_URL}employer/interviewers-list/update`,
        {
          interviewer_ids: [selectedInterviewer.id],
          name: selectedInterviewer.name,
          email: selectedInterviewer.email,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.dismiss()
      toast.success("Interviewer updated successfully");
      setSelectedInterviewer(null);
      setOpenEdit(false);
    } catch (error) {
      console.error("Error updating interviewer:", error);
      toast.dismiss()
      toast.error("Failed to update interviewer");
    }
  };

  const handleFileUpload = useCallback(async () => {
    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      const response = await axios.post(
        `${BASE_URL}employer/interviewers-list/upload`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.dismiss()
      toast.success("Interviewers uploaded successfully");
      setOpen(false); // Assuming `setOpen` controls modal visibility
      setTriggerFetch((prev) => !prev); // Toggle trigger to re-run useEffect
    } catch (error) {
      toast.dismiss()
      toast.error("Something went wrong. Please try again.");
    }
  }, [selectedFile, token, setOpen]);

  const handleDeleteAll = useCallback(async () => {
    // Check if currIndex is valid
    if (currIndex >= 0 && currIndex < interviewers.length) {
      console.log("Selected Index:", currDelete);
      const selectedInterviewer = interviewers[currIndex];
      const deleteData = {
        interviewer_ids: [selectedInterviewer.id],
      };
      console.log("Selected Index:", currIndex);
      console.log("Delete Data:", deleteData);

      try {
        const response = await axios.delete(
          `${BASE_URL}employer/interviewers-list/delete`,
          {
            data: deleteData,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("Delete response:", response);
        if (response.status === 200) {
          toast.dismiss()
          toast.success("Interviewer deleted successfully");
          setTriggerFetch((prev) => !prev); // Toggle trigger to re-run useEffect
          setWarning(false);
        }
      } catch (error) {
        console.error("Error during delete:", error);
      }
    } else {
      console.error("Invalid index:", currIndex);
    }
  }, [ interviewers, token]);

  useEffect(() => {
    const fetchInterviewers = async () => {
      try {
        const response = await axios.get(
          `${BASE_URL}employer/interviewers-list`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          setInterviewers(response.data.interviewers);
        }
      } catch (error) {
        toast.dismiss()
        toast.error("Something went wrong. Please try again.");
      }
    };

    fetchInterviewers();
  }, [token, triggerFetch]);

  return (
    <div>
      <div className="py-8 h-full px-8">
        <div className="flex justify-between items-center">
          <div className="py-4">
            <p className="text-gray-600 text-lg">
              Add and manage interviewers to provide efficient hiring for your
              company.
            </p>
          </div>
          <button
            className="theme-btn text-[#ffffff] flex items-center gap-2"
            onClick={() => setOpen(true)}
          >
            <svg
              width="24"
              height="25"
              viewBox="0 0 24 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_2519_30917)">
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 4.5C12.5523 4.5 13 4.94772 13 5.5V19.5C13 20.0523 12.5523 20.5 12 20.5C11.4477 20.5 11 20.0523 11 19.5V5.5C11 4.94772 11.4477 4.5 12 4.5Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M4 12.5C4 11.9477 4.44772 11.5 5 11.5H19C19.5523 11.5 20 11.9477 20 12.5C20 13.0523 19.5523 13.5 19 13.5H5C4.44772 13.5 4 13.0523 4 12.5Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_2519_30917">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0 0.5)"
                  />
                </clipPath>
              </defs>
            </svg>
            <span className="text-lg text-white font-semibold"><p className="text-white hidden sm:block">Upload</p>Interviewers</span>
          </button>
        </div>

        <div className="w-full mt-8 border border-gray-300 rounded-lg overflow-hidden shadow-lg">
          <table className="w-full table-auto">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">
                  Name
                </th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-600">
                  Email
                </th>
                <th className="px-4 py-2 text-center text-sm font-medium text-gray-600">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {interviewers?.map((interviewer, index) => (
                <tr
                  key={index}
                  className="hover:bg-gray-50 transition-all cursor-pointer"
                >
                  <td className="px-4 py-2">
                     {interviewer.name}
                  </td>
                  <td className="px-4 py-2">{interviewer.email}</td>
                  <td className="px-4 py-2 flex justify-center items-center cursor-pointer">
                    <div
                      onClick={() => {
                        setCurrIndex(index);
                        setCurrDelete(interviewer);
                      }}
                    >
                      {/* Icon Button for the Menu */}
                      <IconButton
                        aria-controls={openA ? "menu-list" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openA ? "true" : undefined}
                        onClick={(event) => handleClick(event)}
                      >
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_76_19235)">
                            <path
                              d="M2.58984 14.4663C3.69441 14.4663 4.58984 13.5709 4.58984 12.4663C4.58984 11.3617 3.69441 10.4663 2.58984 10.4663C1.48527 10.4663 0.589844 11.3617 0.589844 12.4663C0.589844 13.5709 1.48527 14.4663 2.58984 14.4663Z"
                              fill="#374957"
                            />
                            <path
                              d="M12.5898 14.4663C13.6944 14.4663 14.5898 13.5709 14.5898 12.4663C14.5898 11.3617 13.6944 10.4663 12.5898 10.4663C11.4853 10.4663 10.5898 11.3617 10.5898 12.4663C10.5898 13.5709 11.4853 14.4663 12.5898 14.4663Z"
                              fill="#374957"
                            />
                            <path
                              d="M22.5899 14.4663C23.6944 14.4663 24.5899 13.5709 24.5899 12.4663C24.5899 11.3617 23.6944 10.4663 22.5899 10.4663C21.4853 10.4663 20.5898 11.3617 20.5898 12.4663C20.5898 13.5709 21.4853 14.4663 22.5899 14.4663Z"
                              fill="#374957"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_76_19235">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0.589844 0.46582)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </IconButton>

                      {/* Menu */}
                      <Menu
                        id="menu-list"
                        anchorEl={anchorEl}
                        open={openA}
                        onClose={() => {
                          handleCloseA();
                        }} // Closes on outside click
                        sx={{
                          "& .MuiPaper-root": {
                            boxShadow: "none", // Remove shadow
                            border: "1px solid #d1d1d1", // Add 1px border with a light gray color
                            borderRadius: "8px",
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            console.log("Edit Clicked", currIndex);
                            handleEdit();
                          }}
                        >
                          Edit
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            console.log("delete Clicked", currIndex);
                            handleWarning(index,currIndex);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Dialog Box for File Upload */}
      <Dialog open={open} onClose={handleClose} size="xs">
        <DialogTitle>Upload Interviewer List</DialogTitle>
        <DialogContent>
          <Typography variant="body2" color="textSecondary" mb={2}>
            You can upload a Word file or CSV with name and email in order.
          </Typography>

          <div className="mt-4">
            <input
              type="file"
              accept=".csv,.docx"
              onChange={(e) => setSelectedFile(e.target.files[0])}
              className="w-full border border-gray-300 rounded-md p-2 cursor-pointer"
            />
            {selectedFile && (
              <p className="mt-2 text-sm text-gray-500">{selectedFile.name}</p>
            )}
          </div>
        </DialogContent>

        <div className="flex justify-around">
          <DialogActions>
            <Button
              onClick={() => handleFileUpload()}
              color="primary"
              variant="contained"
              className="theme-btn font-semibold py-2 rounded-md"
            >
              Upload
            </Button>
          </DialogActions>
        </div>
      </Dialog>

        <Dialog
          className=" bg-transparent shadow-transparent flex justify-center"
          open={warning}
          size="xs"
          handler={handleWarning}
        >
          <div className="w-[240px] h-[200px] bg-white p-[15px] rounded-[16px] pt-[20px] px-[40px] pb-[30px] shadow-xl border-color">
            <div className=" flex justify-center items-center">
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M24.5 44C21.7333 44 19.1333 43.4747 16.7 42.424C14.2667 41.3733 12.15 39.9487 10.35 38.15C8.55 36.3513 7.12534 34.2347 6.076 31.8C5.02667 29.3653 4.50134 26.7653 4.5 24C4.49867 21.2347 5.024 18.6347 6.076 16.2C7.128 13.7653 8.55267 11.6487 10.35 9.85C12.1473 8.05133 14.264 6.62667 16.7 5.576C19.136 4.52533 21.736 4 24.5 4C27.264 4 29.864 4.52533 32.3 5.576C34.736 6.62667 36.8527 8.05133 38.65 9.85C40.4473 11.6487 41.8727 13.7653 42.926 16.2C43.9793 18.6347 44.504 21.2347 44.5 24C44.496 26.7653 43.9707 29.3653 42.924 31.8C41.8773 34.2347 40.4527 36.3513 38.65 38.15C36.8473 39.9487 34.7307 41.374 32.3 42.426C29.8693 43.478 27.2693 44.0027 24.5 44ZM24.5 40C26.3 40 28.0333 39.7087 29.7 39.126C31.3667 38.5433 32.9 37.7013 34.3 36.6L11.9 14.2C10.8 15.6 9.958 17.1333 9.374 18.8C8.79 20.4667 8.49867 22.2 8.5 24C8.5 28.4667 10.05 32.25 13.15 35.35C16.25 38.45 20.0333 40 24.5 40ZM37.1 33.8C38.2 32.4 39.042 30.8667 39.626 29.2C40.21 27.5333 40.5013 25.8 40.5 24C40.5 19.5333 38.95 15.75 35.85 12.65C32.75 9.55 28.9667 8 24.5 8C22.7 8 20.9667 8.29133 19.3 8.874C17.6333 9.45667 16.1 10.2987 14.7 11.4L37.1 33.8Z"
                  fill="#FF0000"
                />
              </svg>
            </div>
            <p className=" mt-1 font-[500] text-[15px] text-black text-center ">
              Are you sure?
            </p>
            <p className=" text-center font-[400] text-[12px] text-[#86888A] ">
              Do you want to Delete?
            </p>
            <div className=" mt-[10px] flex justify-center gap-[18px] ">
              <button
                onClick={() => {
                  
                  console.log("delete", currDelete);
                  axios.delete(
                    `${BASE_URL}employer/interviewers-list/delete`,
                    {
                      data: { interviewer_ids: [currDelete.id] },
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  setWarning(!warning);
                  setTriggerFetch((prev) => !prev);
                  toast.dismiss()
                  toast.success("Interviewer deleted successfully");
                }}
                className=" py-[4px] px-[25px] text-[#7C8493] rounded-[24px] border-[1.5px] border-[#7C8493] hover:bg-[#7C8493] hover:text-white "
              >
                Yes
              </button>
              <button
                onClick={() => {
                  setWarning(!warning);
                }}
                className=" py-[4px] px-[28px] text-[#7C8493] rounded-[24px] border-[1.5px] border-[#7C8493] hover:bg-[#7C8493] hover:text-white"
              >
                No
              </button>
            </div>
          </div>
        </Dialog>

      <Dialog
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
          // setEditingInterviewer({ id: "", name: "", email: "" });
        }}
        size="xs"
      >
        <DialogTitle>Edit Interviewer</DialogTitle>
        <DialogContent>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedInterviewer?.name || ""}
            onChange={(e) => {
              setSelectedInterviewer({
                ...selectedInterviewer,
                name: e.target.value,
              });
            }}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={selectedInterviewer?.email || ""}
            onChange={(e) => {
              setSelectedInterviewer({
                ...selectedInterviewer,
                email: e.target.value,
              });
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEdit(false);
              setSelectedInterviewer(null);
            }}
            color="secondary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleSaveEdit();
            }}
className="theme-btn font-semibold py-2 rounded-md"
            variant="contained"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default Interviewer;
