import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import {
  NotesOutlined as ReasonIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import config from "../../../config/config";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import { isBefore, isAfter, addDays, startOfDay } from "date-fns";
import Pagination from "./Pagination";

const RescheduleRequestDialog = () => {
  const [open, setOpen] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [allRequests, setAllRequests] = useState([]);
  const [currId, setCurrId] = useState(null);
  const loginData = useSelector((state) => state.auth.Auth);
  const token = loginData.access_token;
  const BASE_URL = config.apiUrl;

  const [selectedDays, setSelectedDays] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    parseInt(localStorage.getItem("currentPageRequest")) || 1
  );
  const [totalPages, setTotalPages] = useState(1);

  // Handle date selection
  const toggleSelectedDay = (date) => {
    // Set the selected date, removing all other dates
    setSelectedDays({ [date]: true });

    setSelectedSlot(null);
  };

  // Handle time slot selection
  const toggleSelectedTimeSlot = (date, time) => {
    setSelectedDays((prev) => {
      // Ensure `selectedTimes` is always an array
      const selectedTimes = Array.isArray(prev[date]) ? prev[date] : [];
      const alreadySelected = selectedTimes.includes(time);

      const updatedTimes = alreadySelected
        ? selectedTimes.filter((t) => t !== time)
        : [time];
      setSelectedSlot(!alreadySelected ? { date, time } : null);

      return {
        ...prev,
        [date]: updatedTimes,
      };
    });
  };

  const disablePastTimeSlots = (time, date) => {
    const currentDate = new Date();
    const selectedDate = new Date(date);

    // Check if the date is in the past
    if (selectedDate < currentDate.setHours(0, 0, 0, 0)) {
      return true;
    }

    // If the date is today, compare time
    if (selectedDate.toDateString() === currentDate.toDateString()) {
      const [hours, minutes] = time.split(":").map(Number);
      const slotTime = new Date(date);
      slotTime.setHours(hours, minutes, 0, 0);

      return slotTime <= currentDate;
    }

    return false;
  };

  // Helper function to format the interview date and time
  const formatInterviewDetails = (interviewDate) => {
    // Create a Date object from the interview_date
    const formattedDate = new Date(interviewDate);

    // Format the date into a human-readable format (e.g., December 20, 2024)
    const dateString = formattedDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });

    // Return the formatted string
    return `${dateString}`;
  };

  // fetch all requests
  const fetchRequests = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}employer/reschedule-requests?page=${currentPage}&page_size=10`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      setAllRequests(response.data.reschedule_requests);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    localStorage.setItem("currentPageRequest", currentPage);
    fetchRequests();
    // remove currentPage
    return () => {
      console.log("cleanup");
      localStorage.removeItem("currentPageRequset");
    };

  }, [open, token, currentPage]);

    // Handle pagination
    const handlePageChange = (newPage) => {
      setAllRequests([]);
      localStorage.setItem("currentPageRequest", newPage);
      setCurrentPage(newPage);
    };

  // Handle opening dialog
  const handleOpenDialog = (id) => {
    setCurrId(id);
    console.log("id", id);
    axios
      .get(`${BASE_URL}employer/reschedule-request/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        console.log(response.data);
        setSelectedCandidate(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    setOpen(true);
  };

  // Handle closing dialog
  const handleCloseDialog = () => {
    setOpen(false);
  };

  // Styling for status based on request status
  // const getStatusStyle = (status) => {
  //   switch (status) {
  //     case "Requests Rescheduled":
  //       return "bg-gray-100 text-gray-800 border-gray-300";
  //     case "Approved":
  //       return "bg-green-100 text-green-800 border-green-300";
  //     case "Rejected":
  //       return "bg-red-100 text-red-800 border-red-300";
  //     default:
  //       return "bg-yellow-100 text-yellow-800 border-yellow-300";
  //   }
  // };

  const columns = [
    { id: "name", label: "Name", align: "left", minWidth: 150 },
    { id: "email", label: "Email", align: "left", minWidth: 200 },
    { id: "jobTitle", label: "Job Title", align: "left", minWidth: 180 },
    {
      id: "dateTime",
      label: "Current Date & Time",
      align: "left",
      minWidth: 250,
    },
    { id: "action", label: "Action", align: "left", minWidth: 120 },
  ];

  // Handle Reject
  const handleReject = () => {
    console.log("Rejecting request", currId);
    axios
      .post(
        `${BASE_URL}employer/reschedule-requests/${currId}/reject`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        toast.dismiss();
        toast.success("Request Rejected Successfully");
        handleCloseDialog();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // Handle Reschedule
  const [openR, setOpenR] = useState(false);

  const handleOpenR = () => setOpenR(true);
  const handleCloseR = () => setOpenR(false);

  // Handle Reschedule
  const handleReschedule = async () => {
    if (!selectedSlot) {
      console.log("Please select a date and time slot");
      toast.dismiss();
      toast.error("Please select a date and time slot");
      return;
    }

    const formattedSlot = {
      accepted_interview_date: selectedSlot.date.split("T")[0],
      accepted_interview_time: {
        start_time: selectedSlot.time.split("-")[0].trim(),
        end_time: selectedSlot.time.split("-")[1].trim(),
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}employer/reschedule-request/${currId}/accept`,
        formattedSlot,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data);
      toast.dismiss();
      toast.success("Request Rescheduled Successfully");
      handleCloseR();
    } catch (error) {
      console.error("Error:", error);
      toast.dismiss();
      toast.error("Failed to reschedule request");
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }} className="mainContent">
      <Toaster />
      <div className="  min-h-screen mt-16 p-6">
        <div>
          <h2 className="menu-heading">Reschedule Requests</h2>
        </div>
        <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
          <TableContainer>
            <Table stickyHeader aria-label="applicants table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {allRequests.map((candidate) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={candidate.id}
                  >
                    <TableCell>{candidate.applicant_name}</TableCell>
                    <TableCell align="left">
                      {candidate?.applicant_email}
                    </TableCell>
                    <TableCell align="left">{candidate.job_title}</TableCell>
                    <TableCell align="left">
                      {formatInterviewDetails(candidate.interview_date)} at{" "}
                      {candidate.interview_time.start_time} -{" "}
                      {candidate.interview_time.end_time}
                    </TableCell>
                    <TableCell align="left">
                      <Button
                        variant="contained"
                        sx={{
                          color: "blue",
                          backgroundColor: "transparent",
                          "&:hover": {
                            textDecoration: "underline",
                            backgroundColor: "transparent",
                          },
                        }}
                        onClick={() => handleOpenDialog(candidate.id)}
                      >
                        View Details
                      </Button>
                    </TableCell>
                    
                  </TableRow>
                ))}
                {allRequests.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      <Typography variant="body2" className="text-gray-500">
                        No reschedule requests found
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {allRequests.length > 0 && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={handlePageChange}
            />)}
        </Paper>

        {/* Dialog for Candidate Details */}
        {selectedCandidate && (
          <Dialog
            open={open}
            onClose={handleCloseDialog}
            maxWidth="md"
            fullWidth
          >
            <DialogTitle>
              <div className="flex items-center space-x-3">
                <Avatar className="bg-blue-500 mr-3">
                  {selectedCandidate.applicant_name?.charAt(0)}
                </Avatar>
                <div>
                  <Typography variant="h6">
                    {selectedCandidate.applicant_name}
                  </Typography>
                  <Typography variant="body2" className="text-gray-600">
                    {selectedCandidate.job_title}
                  </Typography>
                </div>
              </div>
            </DialogTitle>
            <DialogContent dividers>
              {/* Contact Information */}
              <div className="mb-4 bg-gray-100 p-3 rounded-lg">
                <div className="flex items-center space-x-3">
                  <PersonIcon className="text-blue-500" />
                  <div>
                    <Typography variant="caption" className="text-gray-600">
                      Contact Information
                    </Typography>
                    <Typography variant="body2">
                      Email: {selectedCandidate.applicant_email}
                    </Typography>
                  </div>
                </div>
              </div>

              {/* Available Dates and Times */}
              <Typography variant="h6" className="mb-3">
                Candidate Availability Dates and Time
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Available Times</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedCandidate.proposed_datetime.map((slot, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {formatInterviewDetails(slot.proposed_date)}
                        </TableCell>
                        <TableCell>
                          {slot.proposed_time_slots.map((time, timeIndex) => (
                            <Typography
                              key={timeIndex}
                              variant="body2"
                              className="m-2 inline-block bg-blue-100 px-2 py-1 rounded text-blue-800"
                              sx={{ marginRight: 1 }} // Adds space between each time item
                            >
                              {time.start_time} - {time.end_time}
                            </Typography>
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {/* Original Meeting Details */}
              <div className="mt-4 bg-gray-50 p-3 rounded-lg">
                <div className="flex items-center space-x-3">
                  {/* <JobIcon className="text-green-500" /> */}
                  <div>
                    {/* <Typography variant="caption" className="text-gray-600">
                    Original Meeting
                  </Typography> */}
                    {/* <div>
                      <Typography variant="caption" className="text-gray-500">
                        Current Date & Time
                      </Typography>
                      <Typography variant="body2">
                        {selectedCandidate.currentDate} at{" "}
                        {selectedCandidate.currentTime}
                      </Typography>
                    </div> */}
                    <div className="flex items-start space-x-3">
                      <ReasonIcon className="text-purple-500 mt-1" />
                      <div>
                        <Typography
                          variant="caption"
                          className="text-gray-600 mb-1"
                        >
                          Reason for Rescheduling
                        </Typography>
                        <Typography variant="body2" className="text-gray-700">
                          {selectedCandidate?.reason}
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Close
              </Button>
              <Button
                type="button"
                onClick={() => handleReject()}
                variant="contained"
                color="secondary"
              >
                Reject
              </Button>

              <Button
                variant="contained"
                className="theme-btn"
                onClick={() => handleOpenR()}
              >
                Reschedule
              </Button>
            </DialogActions>
          </Dialog>
        )}

        {selectedCandidate && (
          <Dialog open={openR} onClose={handleCloseR}>
            <DialogTitle>Select Interview Date and Time</DialogTitle>
            <DialogContent>
              <Typography className="text-gray-400 text-lg font-semibold">
                Select Date and Time
              </Typography>

              {/* Date Tabs */}
              <div className="flex space-x-2 overflow-x-auto pb-2 border-b">
                {selectedCandidate.proposed_datetime.map((slot, index) => {
                  const proposedDate = new Date(slot.proposed_date);
                  const today = startOfDay(new Date());
                  const lastValidDate = addDays(today, 7); // One week from today

                  const isDateValid =
                    !isBefore(proposedDate, today) &&
                    !isAfter(proposedDate, lastValidDate);

                  return (
                    <button
                      key={index}
                      className={`px-4 py-2 rounded-lg cursor-pointer ${
                        selectedDays[slot.proposed_date]
                          ? "bg-blue-500 text-white"
                          : "bg-gray-200"
                      }`}
                      onClick={() =>
                        isDateValid
                          ? toggleSelectedDay(slot.proposed_date)
                          : null
                      }
                      disabled={!isDateValid}
                    >
                      {formatInterviewDetails(slot.proposed_date)}
                    </button>
                  );
                })}
              </div>

              {/* Time Slots for Selected Date */}
              {selectedCandidate.proposed_datetime.map(
                (slot) =>
                  selectedDays[slot.proposed_date] && (
                    <div key={slot.proposed_date} className="mt-4">
                      <Typography className="font-semibold text-lg text-gray-400">
                        {formatInterviewDetails(slot.proposed_date)} Time Slots
                      </Typography>
                      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
                        {slot.proposed_time_slots.map((time, timeIndex) => (
                          <button
                            key={timeIndex}
                            onClick={() =>
                              toggleSelectedTimeSlot(
                                slot.proposed_date,
                                `${time.start_time}-${time.end_time}`
                              )
                            }
                            className={`px-4 py-2 rounded-lg text-sm ${
                              Array.isArray(selectedDays[slot.proposed_date]) &&
                              selectedDays[slot.proposed_date].includes(
                                `${time.start_time}-${time.end_time}`
                              )
                                ? "bg-blue-500 text-white"
                                : "bg-gray-200"
                            }`}
                            disabled={disablePastTimeSlots(
                              time.start_time,
                              slot.proposed_date
                            )}
                          >
                            {time.start_time} - {time.end_time}
                          </button>
                        ))}
                      </div>
                    </div>
                  )
              )}
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseR} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleReschedule} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </Box>
  );
};

export default RescheduleRequestDialog;
