import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { toggleDrawer, LOGOUT } from "../../../redux copy/actionTypes";
import Modal from "@mui/material/Modal";
import { Box } from "@mui/material";
import config from "../../../config/config";
import { Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Tooltip from "@mui/material/Tooltip";
const drawerWidth = 250;
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};
const AppBarStyled = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 0,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function AppBar() {
  const dispatch = useDispatch();
  const open = useSelector((state) => state.auth.drawerOpen);
  const loginData = useSelector((state) => state.auth.Auth);
  const [companyName, setCompanyName] = useState("");
  const [status, setStatus] = useState(localStorage.getItem("status"));
  const [showSuccess, setShowSuccess] = useState(false);

  const navigate = useNavigate();

  const BASE_URL = "http://localhost:8000/";

  // fetch company info
  useEffect(() => {
    const fetchCompanyInfo = () => {
      axios
        .get(`${BASE_URL}employer/company-info`, {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`, // Ensure token is valid
          },
        })
        .then((res) => {
          console.log("Company Info Response:", res.data);
          if (res.data?.company_name) {
            setCompanyName(res.data.company_name);
          } else {
            console.warn("Company name not found in response.");
          }
        })
        .catch((err) => {
          console.error(
            "Error fetching company info:",
            err.response?.data || err.message
          );
        });
    };

    if(status === "approved") {
      setShowSuccess(true);
    }

    // Add a delay before calling the function
    const timer = setTimeout(fetchCompanyInfo, 100); // 100 ms = 1/10 second

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, [loginData.token]);


  const handleLogout = () => {
    dispatch({ type: LOGOUT });
    setTimeout(() => {
      window.location.reload(true);
      window.location.href = `${config.baseUrl}login`;
    }, 1000);
  };


  const handleDrawerOpen = () => {
    dispatch(toggleDrawer(true));
  };
  const [openModal, setOpenModal] = useState(false);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleJobPost = () => {
    if (status === "approved") {
      navigate(`${config.employerUrl}post-job`);
    } else {
      toast.dismiss();
      toast.error(
        "Your company is under verification. You can post job once it is approved."
      );
    }
  };

  return (
    <AppBarStyled position="fixed" open={open} className="appbar">
      <Toaster />
      <Toolbar>
        <IconButton
          color="#000"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 5,
            ...(open && { display: "none" }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Link to={`${config.employerUrl}dashboard`}>
          <Typography variant="h6" noWrap component="div">
            <h2 className="logo text-2xl font-semibold font-family">
              <span className="color-text font-family">{companyName}</span>
            </h2>
          </Typography>
        </Link>
        {/* Conditional Status Message */}
        {status === "rejected" && (
          <div className="w-[85%] text-center bg-red-100 mb-0">
            <Typography variant="subtitle1" color="error">
              Your company verification has been rejected. Please Re-upload your
              document.
            </Typography>
          </div>
        )}
        {/* {showSuccess && (
        <div
          className="bg-green-300 text-green-800 p-2 rounded-lg shadow-md flex justify-between items-center transition-transform transform w-[75%]"
          style={{
            opacity: 1,
            transform: "translateY(0)",
            transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
          }}
        >
          <div className="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6 mr-2"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 12l2 2 4-4m5 10a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>
            Congratulations! Your company has been successfully approved. You can now post jobs and connect with top talent.
            </span>
          </div>

          <button
            className="ml-4 bg-transparent hover:bg-green-700 rounded-lg p-1"
            onClick={() => setShowSuccess(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      )} */}

        <div className="ms-auto">
          {window.location.pathname !== "/employer/post-job" &&
          window.location.pathname !== "/employer/candidates" &&
          status === "rejected" ? (
            <Tooltip
              title="Your company verification has been rejected. You can't post job."
              arrow
            >
              <button
                type="button"
                className="theme-btn mr-3 disabled"
                onClick={handleJobPost}
                disabled
              >
                + Post job
              </button>
            </Tooltip>
          ) : (
            <button
              type="button"
              className="theme-btn mr-3"
              onClick={handleJobPost}
            >
              + Post job
            </button>
          )}

          {/* <button className='theme-btn ' onClick={handleOpen}>
            Logout
          </button> */}
        </div>
        {/* <PostJobModal openForm={openForm} handleCloseFrom={handleCloseFrom} setOpenForm={setOpenForm} /> */}
      </Toolbar>



      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            className="text-center"
          >
            Are you sure want to logout ?
          </Typography>

          <div className="justify-center items-center mt-5 flex">
            <button
              onClick={handleClose}
              color="primary"
              sx={{ mt: 2 }}
              className="py-2.5 px-5 me-2 mb-2  text-sm font-medium text-black rounded-lg bg-gray-300"
            >
              No
            </button>
            <button
              onClick={handleLogout}
              sx={{ mt: 2 }}
              className=" py-2.5 px-5 me-2 mb-2 text-white text-sm font-medium text-wh theme-btn   rounded-lg"
            >
              Yes
            </button>
          </div>
        </Box>
      </Modal>
    </AppBarStyled>
  );
}
