import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import DrawerHeader from "../DrawerHeader";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../../config/config";
import { toast} from "react-hot-toast";
import UpcomingInterviews from "./UpcomingInterviews";
import { useNavigate } from "react-router-dom";
import Loader from "../../../utils/Loader";

import { Doughnut } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
} from "chart.js";

// Register necessary chart.js components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default function MainContent() {
  const [Candidate_Info, setCandidate_Info] = React.useState({
    application_stats: {
      applied_jobs: 0,
      saved_jobs: 0,
      interviewed_percentage: 0,
      unsuitable_percentage: 0,
    },
    upcoming_interviews: [],
    recommendations: [],
  });
  const loginData = useSelector((state) => state.auth.Auth);
  const [companyLogos, setCompanyLogos] = useState({});
  const [appliedJobs, setAppliedJobs] = useState({});
  const [loading, setLoading] = useState(false);
  const [greeting, setGreeting] = useState("");

  const navigate = useNavigate();

  // Greeting message
  useEffect(() => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }
  }, []);

  const handleNavigate = () => {
    navigate("/candidate/recomendation"); // Update the path to match your route
  };

  const saveJob = async (jobId) => {
    try {
      console.log("access token", loginData.access_token);
      const response = await axios.post(
        `${config.apiUrl}candidate/save-job/${jobId}`,
        {}, // Empty body if no data is required
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 201) {
        // console.log("Job saved successfully!", response.data);
        toast.dismiss();
        toast.success(response.data.message);
        getCandidateInfo();
      }
    } catch (error) {
      console.error("Error saving job:", error);
      // Handle error - you might want to display an error message to the user
    }
  };
  const unSaveJob = async (jobId) => {
    try {
      console.log("access token", loginData.access_token);
      const response = await axios.post(
        `${config.apiUrl}candidate/unsave-job/${jobId}`,
        {}, // Empty body if no data is required
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );

      if (response.status === 200) {
        // console.log("Job saved successfully!", response.data);
        toast.dismiss();
        toast.success(response.data.message);
        getCandidateInfo();
      }
    } catch (error) {
      console.error("Error saving job:", error);
      // Handle error - you might want to display an error message to the user
    }
  };

  const fetchCompanyLogo = async (logoId) => {
    try {
      if(logoId === 'None') return "";
      if (companyLogos[logoId]) return companyLogos[logoId];
      const response = await axios.get(
        `${config.apiUrl}candidate/company-logo/${logoId}`,
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
          responseType: "blob",
        }
      );
      const logoUrl = URL.createObjectURL(response.data);
      setCompanyLogos((prev) => ({ ...prev, [logoId]: logoUrl }));
    } catch (error) {
      console.log("Error fetching company logo:", error);
      return null;
    }
  };
  useEffect(() => {
    Candidate_Info.recommendations.forEach((job) => {
      if (job.company_logo_id && !companyLogos[job.company_logo_id]) {
        fetchCompanyLogo(job.company_logo_id);
      }
    });

    return () => {
      Object.values(companyLogos).forEach((url) => URL.revokeObjectURL(url)); // Clean up blob URLs
    };
  }, [Candidate_Info, companyLogos]);

  const fetchCompanyLogosBatch = async (logoIds) => {
    try {
      const response = await axios.post(`${config.apiUrl}company/logos/batch`, {
        ids: logoIds,
      });
      return response.data;
    } catch (error) {
      console.error("Error fetching company logos:", error);
      return {};
    }
  };
      // Function to fetch the company logo using the logoId

  const applyForJob = async (jobId) => {
    try {
      const response = await axios.post(
        `${config.apiUrl}candidate/apply-job/${jobId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        }
      );
      if (response.status === 201) {
        toast.dismiss();
        toast.success(response.data.message);
        setAppliedJobs((prev) => ({ ...prev, [jobId]: true }));
        getCandidateInfo();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.response.data.detail);
      console.log(error);
    }
  };

  const getCandidateInfo = async () => {
    try {
      console.log("Token" + loginData.access_token);
      setLoading(true);
      const response = await axios.get(`${config.apiUrl}candidate/dashboard`, {
        headers: {
          Authorization: `Bearer ${loginData.access_token}`,
        },
      });
      console.log(response.data);
      if (response.status === 200) {
        setCandidate_Info(response.data);
        const logoIds = response.data.recommendations
          .filter((job) => job.company_logo_id && !companyLogos[job.id])
          .map((job) => job.company_logo_id);

        // if (logoIds.length > 0) {
        //   const logos = await fetchCompanyLogosBatch(logoIds);

        //   const logoMap = response.data.reduce((acc, job) => {
        //     if (logos[job.company_logo_id]) {
        //       acc[job.id] = logos[job.company_logo_id];
        //     }
        //     return acc;
        //   }, {});

        //   setCompanyLogos((prevLogos) => ({ ...prevLogos, ...logoMap }));
        // }
      }
    } catch (error) {
      console.error("Error:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getCandidateInfo();
  }, []);
  const createGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradient.addColorStop(0, "rgba(26, 217, 223, 1)"); // Start color
    gradient.addColorStop(1, "rgba(168, 36, 254, 1)"); // End color
    return gradient;
  };

  const createHoverGradient = (ctx, chartArea) => {
    const gradient = ctx.createLinearGradient(
      0,
      chartArea.top,
      0,
      chartArea.bottom
    );
    gradient.addColorStop(0, "#5670f5"); // Start hover color
    gradient.addColorStop(1, "#42a5f5"); // End hover color
    return gradient;
  };

  const data = {
    labels: ["Unsuitable", "Interviewed"],
    datasets: [
      {
        data: [
          Candidate_Info.application_stats.unsuitable_percentage,
          Candidate_Info.application_stats.interviewed_percentage,
        ],
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          // Fallback while rendering
          if (!chartArea) {
            return "#dadbe0";
          }

          return [
            createGradient(ctx, chartArea), // Gradient for the first segment
            "#dadbe0", // Static color for the second segment
          ];
        },
        hoverBackgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          // Fallback while rendering
          if (!chartArea) {
            return "#dadbe0";
          }

          return [
            createHoverGradient(ctx, chartArea), // Gradient for the first segment on hover
            "#dadbe0", // Static color for the second segment on hover
          ];
        },
        borderWidth: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: true, // Tooltips enabled
      },
    },
    cutout: "65%",
  };

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      <DrawerHeader />
      {/* <UploadResumeModal /> */}
      {!loading ? (
        <div>
          <div className="flex justify-between items-center bg-gray-50 mt-3 p-6 pr-10">
            {/* Left Section */}
            <div>
              <h1 className="text-2xl font-semibold text-gray-800">
                {greeting} ,{" "}
                <h3 className=" inline-block text-sm text-gray-500">
                  {Candidate_Info.name}
                </h3>
              </h1>

              <p className="text-sm text-gray-600">
                Here is your daily activities and job alerts.
              </p>
            </div>
          </div>
          <div className="flex flex-wrap gap-6 p-6 bg-gray-50">
            {/* Left Panel */}
            <div className="w-full md:w-1/4 flex flex-col gap-4">
              {/* Applied Jobs */}
              <div className=" flex flex-col justify-between bg-white border border-gray-200 rounded-2xl p-3 text-center">
                <div className="ml-3 mr-5 flex items-start">
                  <p className="text-gray-500 text-sm font-bold">
                    Applied jobs
                  </p>
                </div>
                <div className="mr-5  ml-3 flex  justify-between items-center mt-1">
                  <h2 className="text-3xl font-bold mt-1">
                    {Candidate_Info["application_stats"].applied_jobs}
                  </h2>
                  <img src="/images/icons/document.svg" alt="logo" />
                </div>
              </div>
              {/* Saved Jobs */}
              <div className=" flex flex-col justify-between bg-white border border-gray-200 rounded-2xl p-3 text-center">
                <div className="ml-3 mr-5 flex items-start">
                  <p className="text-gray-500 text-sm font-bold">Saved Jobs</p>
                </div>
                <div className="mr-5  ml-3 flex  justify-between items-center mt-1">
                  <h2 className="text-3xl font-bold mt-1">
                    {Candidate_Info.application_stats.saved_jobs}
                  </h2>
                  <img src="/images/icons/bookmark.svg" alt="logo" />
                </div>
              </div>

              <div className=" flex flex-col justify-between bg-white border border-gray-200 rounded-2xl p-3 text-center">
                <div className="ml-3 mr-5 flex items-start">
                  <p className="text-gray-500 text-sm font-bold">Pending Interview</p>
                </div>
                <div className="mr-5  ml-3 flex  justify-between items-center mt-1">
                  <h2 className="text-3xl font-bold mt-1">
                  {Candidate_Info?.application_stats?.interview_stats?.pending_interviews || 0}
                  </h2>
                  <img src="/images/icons/bookmark.svg" alt="logo" />
                </div>
              </div>

              {/* Job Alerts */}
              {/* <div className=" flex flex-col justify-between bg-white border border-gray-200 rounded-2xl p-3 text-center">
                <div className="ml-3 mr-5 flex items-start">
                  <p className="text-gray-500 text-sm font-bold">Job Alerts</p>
                </div>
               
                <div className="mr-5  ml-3 flex  justify-between items-center mt-1">
                  <h2 className="text-3xl font-bold mt-1">25</h2>
                  <img src="/images/icons/bell.svg" />
                </div>
              </div> */}
            </div>

            {/* Middle Panel */}
            <div className="w-full md:w-1/3 bg-white border border-gray-200 rounded-2xl p-6">
              <h3 className="text-lg font-semibold text-gray-700 mb-4">
                Jobs Applied Status
              </h3>
              <div className="flex items-center h-40 gap-8">
                <Doughnut data={data} options={options} />
                <div className="bg-white rounded-full flex flex-col gap-2 items-center ">
                  <div className="flex justify-between gap-4">
                    <div className="flex items-center">
                      <div
                        className="rounded w-5 h-5"
                        style={{
                          background:
                            "linear-gradient(150deg, rgba(26, 217, 223, 1) 0%, rgba(168, 36, 254, 1) 100%)",
                        }}
                      ></div>
                    </div>
                    <div className="flex flex-col justify-center">
                      <div className="font-semibold">
                        {Candidate_Info.application_stats.unsuitable_percentage}
                        %
                      </div>
                      <div className="text-center text-gray-500">
                        Unsuitable
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-between gap-4">
                    <div className="flex items-center">
                      <div className="rounded bg-gray-300 w-5 h-5"></div>
                    </div>
                    <div className="flex flex-col justify-center">
                      <div className="font-semibold">
                        {
                          Candidate_Info.application_stats
                            .interviewed_percentage
                        }
                        %
                      </div>
                      <div className="text-center text-gray-500">
                        Interviewed
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <a
                  href="candidate/my-applications"
                  className="text-blue-600 text-sm font-semibold hover:underline"
                >
                  View All Applications →
                </a>
              </div>
            </div>

            {/* Right Panel */}
            <UpcomingInterviews
              upcoming_interviews={Candidate_Info.upcoming_interviews}
            />
          </div>
          <div className="bg-gray-50 p-6">
            <div className="flex justify-between">
              <h2 className="menu-heading pl-0 lg:mb-4">Recommendations</h2>
              <div
                className="flex items-center text-black-600 text-lg cursor-pointer"
                onClick={handleNavigate}
              >
                See All <KeyboardArrowRightIcon />
              </div>
            </div>
            <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-4">
              {Candidate_Info.recommendations.map((item) => (
                <div className="card1 mb-3 bg-white" key={item.id}>
                  <div className="card-data">
                    <div className="flex items-center gap-x-4">
                      {/* Display company logo if available */}
                      {companyLogos[item.company_logo_id] ? (
                        <img
                          src={companyLogos[item.company_logo_id]}
                          alt="Company logo"
                          className="company-logo"
                        />
                      ) : (
                        <img
                          src="images/default.png"
                          alt="Default logo"
                          className="company-logo"
                        />
                      )}
                      <div>
                        <h4>{item.title}</h4>
                        <p>
                          {item.location} {item.salary}
                        </p>
                      </div>
                      <button className="md:p-1 ml-auto lg:p-2 flex items-center justify-center lg:h-10 lg:w-10 lg:h-8 lg:w-8 bg-[#E7E7E7] rounded-full">
                        {" "}
                        {/* Pass job ID */}
                        <img
                          src="images/icons/send-arrow.svg"
                          className="brand-icon"
                          alt="view details"
                        />
                      </button>
                    </div>
                    <div className="my-5 type-btns flex gap-1">
                      <button
                        disabled
                        className="flex justify-center items-center text-[12px] bg-[#E6E6E6] p-1 px-4  rounded-[24px]"
                      >
                        <img src="images/icons/location.svg" alt="logo" />
                        Hybrid
                      </button>
                      <button
                        disabled
                        className="flex justify-center items-center text-[12px]  bg-[#E6E6E6] p-1 px-2 rounded-[24px]"
                      >
                        <img src="images/icons/cap.svg" alt="logo" />
                        3+ yrs exp.
                      </button>
                      <button
                        disabled
                        className="flex justify-center items-center text-[12px]  bg-[#E6E6E6] p-1 px-2  rounded-[24px]"
                      >
                        <img src="images/icons/clock.svg" alt="logo" />
                        Full-time
                      </button>
                    </div>
                    <div className="flex justify-between ">
                      <span>
                        <img
                          src="images/icons/history.svg"
                          className="md:mr-1 lg:mr-2 inline-block"
                          alt="posted date"
                        />
                        {item.posted_date}
                      </span>
                      <span>Np : {item.notice_period}</span>
                    </div>
                  </div>
                  <div className="foot-content items-center flex px-6 py-3">
                    <button
                      type="button"
                      className={` text-white text-sm  mr-3 ${item.applied_status === true ? " bg-gray-300 px-4 p-1 rounded-[24px]" : "theme-btn"}`}
                      onClick={() => applyForJob(item.id)}
                      disabled={appliedJobs[item.id]}
                    >
                      {item.applied_status === true ? "Applied" : "Apply Now"}
                    </button>
                    <div className="ml-auto">
                      {/* <button type='button'><img src='images/icons/share.svg' className='mr-4' alt="share" /></button> */}
                      <button type="button">
                        {item.is_saved ? (
                          <img
                            src="images/icons/save2.svg"
                            alt="save"
                            onClick={() => unSaveJob(item.id)}
                          />
                        ) : (
                          <img
                            src="images/icons/save.svg"
                            alt="save"
                            onClick={() => saveJob(item.id)}
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </Box>
  );
}
