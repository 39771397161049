import React from 'react'
import JobSearch from './JobSearch'
import AppBar from '../Appbar'
import Drawer from '../Drawer'
import { Box, CssBaseline } from '@mui/material';
import DrawerHeader from '../DrawerHeader';
function FindJob() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar />
            <Drawer />
            <Box component="main" sx={{ flexGrow: 1 }} className="mainContent">
                <DrawerHeader />
                <JobSearch />              
            </Box>
            
        </Box>
    )
        
}

export default FindJob