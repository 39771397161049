import React, { useState, useEffect } from "react";
import config from "../../../config/config";
import * as Yup from "yup";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "../../../redux copy/actionTypes";
import CircularProgress from "@mui/material/CircularProgress";

const WithOTP = ({ switchToPassword }) => {
  const [company_email, setCompany_email] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [loading, setLoading] = useState(false); // loading state
  const [timer, setTimer] = useState(30); // 2-minute timer (30 seconds)
  const [canResendOtp, setCanResendOtp] = useState(false); // control OTP resend button
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [otpErrors, setOtpErrors] = useState("");

  // Timer effect
  useEffect(() => {
    let interval;
    if (showOtpInput && timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResendOtp(true); // Allow OTP resend after timer hits 0
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [showOtpInput, timer]);

  // Reset timer function
  const resetTimer = () => {
    setTimer(30); // Reset to 2 minutes
    setCanResendOtp(false);
  };

  const validationSchema = Yup.object().shape({
    company_email: Yup.string()
      .email("Please enter a valid email address") // Validates email format
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Please enter a valid email address"
      )
      .required("Please enter a valid email address"), // Checks if the email is not empty
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ company_email }, { abortEarly: false });
      setLoading(true);
      const response = await axios.post(`${config.apiUrl}employer/login`, {
        company_email,
      });
      if (response.status === 200) {
        toast.success(response.data.message);
        setShowOtpInput(true);
        resetTimer(); // Start/restart the timer
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  // Handle OTP input change
  const handleOtpChange = (e, index) => {
    const value = e.target.value;
    if (/\D/.test(value)) return; // Prevent non-numeric values

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Focus the next input box if there's a value entered
    if (value && index < otp.length - 1) {
      document.getElementById(`otp-input-${index + 1}`).focus();
    }
  };

  // Handle keydown for backspace
  const handleOtpKeyDown = (e, index) => {
    if (e.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-input-${index - 1}`).focus(); // Focus the previous input box
    }
  };

  // Handle OTP paste
  const handleOtpPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    if (!/^\d+$/.test(pastedData)) return; // Ensure only numeric values are pasted

    const pastedArray = pastedData.split("");
    const newOtp = [...otp];
    for (let i = 0; i < pastedArray.length && i < otp.length; i++) {
      newOtp[i] = pastedArray[i];
    }
    setOtp(newOtp);

    // Focus the next input box after pasting
    const nextIndex = Math.min(pastedArray.length, otp.length - 1);
    document.getElementById(`otp-input-${nextIndex}`).focus();
  };
  const handleOtpSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    const otpValue = otp.join("").trim(); // Join OTP array and trim any whitespace
  
    // Validate OTP locally before making the API request
    if (!otpValue || otpValue.length !== 6) {
      setOtpErrors("Please enter a valid 6-digit OTP");
      setLoading(false); // Stop loading if validation fails
      return;
    }
  
    try {
      console.log("Email:", company_email);
      console.log("OTP:", otpValue);
  
      const response = await axios.post(
        `${config.apiUrl}employer/login/otp/verify`,
        {
          company_email, // Email from state
          otp: otpValue, // Joined OTP string
        },
        {
          headers: {
            "Content-Type": "application/json", // Ensure proper header
          },
        }
      );
  
      if (response.status === 200) {
        console.log("OTP Verified:", response.data);
  
        // Dispatch to Auth context or store
        dispatch({
          type: Auth,
          payload: response.data,
        });
  
        toast.success("OTP verified successfully!");
      } else {
        toast.error("Failed to verify OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error Verifying OTP:", error);
  
      // Handle specific error response from the server
      if (error.response) {
        const errorMessage =
          error.response.data.detail || "An error occurred while verifying OTP";
        setOtpErrors(errorMessage); // Set error message in the UI
        toast.error(errorMessage); // Show toast notification
      } else {
        toast.error("Network error. Please try again.");
      }
    } finally {
      setLoading(false); // Stop the loader
    }
  };
  

  const handleResendOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${config.apiUrl}employer/login`,
        { company_email }
      );
      if (response.status === 200) {
        toast.success("OTP resent successfully");
        resetTimer(); // Restart the timer
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };
  const handleErrors = (error) => {
    const validationErrors = {};

    // Check if the error comes from the API
    if (error.response) {
      const apiErrorDetail = error.response.data?.detail;

      // Handle string errors (e.g., "Oops! This email address is not registered.")
      if (typeof apiErrorDetail === "string") {
        // If the error is related to the email, show it as an email error
        if (apiErrorDetail.toLowerCase().includes("email")) {
          validationErrors.company_email = apiErrorDetail; // Set email error
        } else {
          // Otherwise, set it as a general error
          validationErrors.general = apiErrorDetail; // Set general error
        }
      }
      // Handle array errors (field-specific errors)
      else if (Array.isArray(apiErrorDetail)) {
        apiErrorDetail.forEach((err) => {
          if (err.loc && err.loc.length === 2) {
            const field = err.loc[1]; // Extract field name (e.g., "email")
            validationErrors[field] = err.msg; // Set field-specific errors
          }
        });
      }
      // Fallback for generic API error
      else {
        validationErrors.general = "Something went wrong with the API.";
      }
    }
    // Handle Yup validation errors
    else if (error instanceof Yup.ValidationError) {
      error.inner.forEach((err) => {
        validationErrors[err.path] = err.message; // Set Yup validation errors
      });
    }
    // Fallback for unexpected errors
    else {
      console.error(error);
      validationErrors.general = "An unexpected error occurred.";
    }

    // Set the error messages to state
    setErrors(validationErrors);
  };

  return (
    <>
      <Toaster />
      <div className="flex flex-col justify-center  mt-24 ml-6">
          <form className="space-y-4" onSubmit={handleLogin}>
{/* register Company */}
            <span className="text-2xl font-bold text-gray-800 text-center">
              Register your <span className="color-text">company </span> to get started
            </span>


            <div>
              <label className="block">
                Company Email<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="email"
                name="company_email"
                value={company_email}
                onChange={(e) => {
                  setCompany_email(e.target.value);
                  setErrors((prevErrors) => ({
                    ...prevErrors,
                    company_email: undefined,
                  }));
                }}
                className="mt-1 block w-full p-3 border shadow-sm placeholder-gray-400 focus:outline-none sm"
                placeholder="Enter your email"
                disabled={showOtpInput}
              />
              {errors.company_email && (
                <span className="text-red-500 text-xs">
                  {errors.company_email}
                </span>
              )}
            </div>
            <div>
            {!showOtpInput ? (
              <button
                type="submit"
                className="theme-btn rounded-md mr-2 text-white w-full"
                onClick={handleLogin}
                disabled={showOtpInput}
              >
                Send OTP
                {loading && (
                  <CircularProgress
                    size={20}
                    disableShrink
                    style={{ color: "#fff", margin: "0 10px" }}
                  />
                )}
              </button>
              ) : (
                <div >
                  {/* Display Email (Disabled) */}
                  {/* OTP Inputs */}
                  <p className="text-sm text-gray-600 mb-4 text-left ml-3">
                    Enter the 6-digit code sent to your email
                  </p>
                  <form className="space-y-4 mt-4 " onSubmit={handleOtpSubmit}>
                    <div className="flex justify-center gap-2">
                      {[...Array(6)].map((_, index) => (
                        <input
                          key={index}
                          id={`otp-input-${index}`}
                          type="text"
                          maxLength={1}
                          className="w-10 h-12 sm:w-12 sm:h-12 text-center border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 text-lg"
                          value={otp[index] || ""}
                          onChange={(e) => handleOtpChange(e, index)}
                          onKeyDown={(e) => handleOtpKeyDown(e, index)}
                          onPaste={handleOtpPaste}
                        />
                      ))}
                    </div>
                    {/* Error Message */}
                    {otpErrors && (
                      <p className="text-red-500 text-sm text-center">{otpErrors}</p>
                    )}

                    {/* Submit Button */}
                    <div className="text-center">
                      <button
                        type="submit"
                        className="theme-btn bg-blue-500 hover:bg-blue-600 rounded-md text-white px-4 py-2 flex items-center justify-center w-full sm:w-auto"
                        onClick={handleOtpSubmit}
                      >
                        Continue
                        {loading && (
                          <CircularProgress
                            size={20}
                            disableShrink
                            style={{ color: "#fff", marginLeft: "10px" }}
                          />
                        )}
                      </button>
                    </div>
                    {timer > 0 ? (
                      <p className="text-sm">
                        Resend OTP in {Math.floor(timer / 60)}:
                        {(timer % 60).toString().padStart(2, "0")}
                      </p>
                    ) : (
                      <p className="text-sm cursor-pointer">
                        Didn't get the OTP?{" "}
                        <span
                          className="text-blue-500 cursor-pointer"
                          onClick={handleResendOtp}
                        >
                          Resend OTP
                        </span>
                      </p>
                    )}
                  </form>
                </div>
              )}
            </div>
          </form>
      </div>
    </>
  );
};

export default WithOTP;
