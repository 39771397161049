import axiosClient from "../../config/axios.Config";

export const UploadProfileAPI = (payload, token) => {
  return axiosClient("candidate/upload_photo", payload, "POST", {
    Authorization: token,
  });
};
export const getJobRecommendations = (token,page) => {
  return axiosClient(`candidate/recommendations?page=${page}&page_size=6`, "", "Get", {
    Authorization: `Bearer ${token}`,
  });
};
export const getSavedJobs = (token,page) => {
  return axiosClient(`candidate/saved-jobs?page=${page}&page_size=6`, "", "Get", {
    Authorization: `Bearer ${token}`,
  });
};

export const getLocation = () => {
  return axiosClient("candidate/indian-states", "", "GET");
};
export const getSkills = () => {
  return axiosClient("candidate/skills", "", "GET");
};
export const getCountryColde = () => {
  return axiosClient("candidate/country-codes", "", "GET");
};
export const getFieldStudy = () => {
  return axiosClient("candidate/fields-of-study", "", "GET");
};
export const getCandidateProfile = (token) => {
  return axiosClient(`candidate/profile`, "", "Get", {
    Authorization: `Bearer ${token}`,
  });
};
export const getAllCv = (token) => {
  return axiosClient(`candidate/get_all_cv`, "", "Get", {
    Authorization: `Bearer ${token}`,
  });
};

export const getInterviewPage = (token) => {
  return axiosClient(`candidate/interview-dashboard`, "", "Get", {
    Authorization: `Bearer ${token}`,
  });
};

export const jobList = (token) => {
  return axiosClient(`candidate/job-roles`, "", "Get", {
    Authorization: `Bearer ${token}`,
  });
}
