import React, {  useState } from "react";
import Header from "../../directories/header";
import Employer from "./employer";
import Candidate from "./candidate";
// import Snowfall from 'react-snowfall';
// import Snowfalls from "../design/SnowFalls";
// import ChristmasLights from "../design/ChristmasLights";
// import Fire from "../design/Fire";
// import NewYearImage from "../design/NewYear";

const Home = () => {



    const [showComponent, setShowComponent] = useState('candidate');
    const [activeButton, setActiveButton] = useState('candidate');

    const handleShowEmployer = () => {
        setShowComponent('employer');
        setActiveButton('employer');
    };

    const handleShowCandidate = () => {
        setShowComponent('candidate');
        setActiveButton('candidate');
    };

    // const [isDark, setIsDark] = useState(false);

    // // const toggleTheme = () => {
    // //     setIsDark(!isDark);
    // // };

    // useEffect(() => {
    //     document.documentElement.classList.toggle("dark", isDark);
    // }, [isDark]);

    return (
        <>
                {/* <div>
            <button onClick={toggleTheme}>
                {isDark ? "Switch to Light Mode" : "Switch to Dark Mode"}
            </button>
        </div> */}
        {/* <Snowfalls />
        <Snowfall color="#F0F8FF" snowflakeCount={1500} className="pointer-events-none fixed inset-0 z-[9999]" /> */}
        {/* <ChristmasLights /> */}
        {/* new year image */}
        {/* <NewYearImage /> */}

        {/* <div
        className="absolute top-0 left-0 w-full h-full z-0 pointer-events-none"
        style={{
          overflow: "hidden",
        }}
      >
        <Fire />
      </div> */}

            <Header userType={showComponent} />
            <div className="container mx-auto ">
                <button
                    type="button"
                    className={`me-2 bordered-btn theme-btn ${activeButton === 'candidate' ? 'active' : ''}`}
                    onClick={handleShowCandidate}
                >
                    <span className="color-text">Candidate</span>
                </button>
                <button
                    type="button"
                    className={`me-2 bordered-btn theme-btn ${activeButton === 'employer' ? 'active' : ''}`}
                    onClick={handleShowEmployer}
                >
                    <span className="color-text">Employer</span>
                </button>

            </div>
            {showComponent === 'employer' && <Employer />}
            {showComponent === 'candidate' && <Candidate />}
        </>
    );
};

export default Home;
