import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import config from "../../../../config/config";
import {
  getLocation,
  jobList,
} from "../../../../utils/candidate.api/candidate.api.services";
import * as Yup from "yup";
import { toast, Toaster } from "react-hot-toast";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
} from "@mui/material";

const Prefrence = () => {
  const loginData = useSelector((state) => state.auth.Auth);
  const [location, setLocation] = useState([]);
  const [isJobTypeOpen, setIsJobTypeOpen] = useState(false);
  const [isJobModeOpen, setIsJobModeOpen] = useState(false);
  const [jobRoles, setJobRoles] = useState([]);

  const [formData, setFormData] = useState({
    job_types: [],
    job_modes: [],
    current_location: "",
    preferred_location: "",
    notice_period: "",
    work_experience: "",
    current_salary: "",
    expected_salary_min: "",
    expected_salary_max: "",
    preferred_job_role: "",
    remaining_working_days: "",
  });

  const validationSchema = Yup.object().shape({
    job_types: Yup.array()
      .of(Yup.string()) // Ensures each item in the array is a string
      .min(1, "Select at least one job type"), // Validates array size
    job_modes: Yup.array()
      .of(Yup.string()) // Ensures each item in the array is a string
      .min(1, "Select at least one job mode"),
    current_location: Yup.string().required("Current location is required"),
    preferred_location: Yup.string().required("Preferred location is required"),
    notice_period: Yup.string().required("Notice period is required"),
    work_experience: Yup.string().required("Work experience is required"),
    preferred_job_role: Yup.string().required("Job category is required"),
    current_salary: Yup.number()
      .typeError("Current salary must be a number")
      .min(0, "Current salary must be zero or positive")
      .required("Current salary is required"),
    expected_salary_min: Yup.number()
      .typeError("Minimum expected salary must be a number")
      .positive("Minimum expected salary must be positive")
      .required("Minimum expected salary is required"),
    expected_salary_max: Yup.number()
      .typeError("Maximum expected salary must be a number")
      .positive("Maximum expected salary must be positive")
      .required("Maximum expected salary is required"),
    remaining_working_days: Yup.lazy((value, { parent }) =>
      parent.notice_period === "Serving notice period" ||
      parent.notice_period === "Immediate/15 days"
        ? Yup.string().required("Rem. Working Days is required")
        : Yup.string().notRequired()
    ),
  });

  const jobTypes = ["Full-time", "Internship", "Contractual"];
  const jobModes = ["Hybrid", "Remote", "On-site"];

  // References for dropdown containers
  const jobTypeRef = useRef(null);
  const jobModeRef = useRef(null);

  const toggleOption = (value, field) => {
    setFormData((prevData) => ({
      ...prevData,
      [field]: prevData[field].includes(value)
        ? prevData[field].filter((item) => item !== value)
        : [...prevData[field], value],
    }));
  };

  useEffect(() => {
    const fetchJobRoles = async () => {
      try {
        const response = await jobList(loginData.access_token);
        setJobRoles(response.data);
      } catch (error) {
        console.error("Error fetching job roles:", error);
      }
    };
    fetchJobRoles();
  }, []);

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        jobTypeRef.current &&
        !jobTypeRef.current.contains(event.target) &&
        !jobModeRef.current.contains(event.target)
      ) {
        setIsJobTypeOpen(false);
        setIsJobModeOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const [errors, setErrors] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const handleChange = async (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
    if (
      name === "notice_period" &&
      value !== "Serving notice period" &&
      value !== "Immediate/15 days"
    ) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        remaining_working_days: "",
      }));
    }
    try {
      await validationSchema.validateAt(name, { [name]: value });
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    } catch (error) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: error.message }));
    }
  };

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        let response = await getLocation();
        setLocation(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchLocation();
  }, []);

  const noticePeriod = [
    { name: "Immediate/15 days" },
    { name: "30 days" },
    { name: "60 days" },
    { name: "90 days or more" },
    { name: "Serving notice period" },
  ];
  useEffect(() => {
    const fetchPreferenceData = async () => {
      try {
        const res = await axios.get(`${config.apiUrl}candidate/preferences`, {
          headers: {
            Authorization: `Bearer ${loginData.access_token}`,
          },
        });
        if (res.data && res.data.length > 0) {
          const preferData = res.data[0];
          console.log(res.data);
          setFormData({
            job_types: preferData.job_types,
            job_modes: preferData.job_modes,
            current_location: preferData.current_location,
            preferred_location: preferData.preferred_location,
            notice_period: preferData.notice_period,
            work_experience: preferData.work_experience,
            current_salary: preferData.current_salary,
            expected_salary_min: preferData.expected_salary_min,
            expected_salary_max: preferData.expected_salary_max,
            preferred_job_role: preferData.preferred_job_role,
            remaining_working_days: preferData.remaining_working_days,
          });
          setIsUpdating(true);
        }
      } catch (error) {
        console.error("Error fetching profile data:", error);
      }
    };

    fetchPreferenceData();
  }, []);
  const handleSubmit = async (e) => {
    console.log(formData);
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      console.log("Form data:", formData);
      if (isUpdating) {
        const response = await axios.patch(
          `${config.apiUrl}candidate/preferences`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );
        if (response.status == 200) {
          toast.dismiss();
          toast.success(response.data.message);
        }
      } else {
        const response = await axios.post(
          `${config.apiUrl}candidate/preferences`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${loginData.access_token}`,
            },
          }
        );
        if (response.status == 201) {
          toast.dismiss();
          toast.success(response.data.message);
        }
      }
    } catch (error) {
      console.log("this is", error);
      if (error instanceof Yup.ValidationError) {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
        // console.log(validationErrors);
        setErrors(validationErrors);
      } else {
        console.error("Unexpected error:", error);
      }
    }
  };
  return (
    <>
      <Toaster />
      <div>
        <form className="mt-10 form-theme">
          <div className="grid gap-x-6 gap-y-3 mb-6 md:grid-cols-3">
            {/* Job Type Dropdown */}
            <div className="mb-4 relative" ref={jobTypeRef}>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Job Type<span className="text-red-500">*</span>
              </label>
              <div
                className="border border-gray-300 rounded-md pl-3 py-[6px]  cursor-pointer text-sm flex justify-between items-center"
                onClick={() => {
                  setIsJobTypeOpen(!isJobTypeOpen);
                  setIsJobModeOpen(false);
                }}
              >
                {formData.job_types.length > 0
                  ? formData.job_types.join(", ")
                  : "Select Job Type"}
                  <svg
                  className="h-4 w-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {isJobTypeOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  {jobTypes.map((type) => (
                    <label
                      key={type}
                      className="flex items-center px-3 py-2 hover:bg-gray-100"
                    >
                      <input
                        type="checkbox"
                        checked={formData.job_types.includes(type)}
                        onChange={() => toggleOption(type, "job_types")}
                        className="mr-2"
                      />
                      {type}
                    </label>
                  ))}
                </div>
              )}
            </div>

            {/* Job Mode Dropdown */}
            <div className="relative" ref={jobModeRef}>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Job Mode<span className="text-red-500">*</span>
              </label>

              <div
                className="border border-gray-300 rounded-md pl-3 py-[6px]   cursor-pointer text-sm flex justify-between items-center"
                onClick={() => {
                  setIsJobModeOpen(!isJobModeOpen);
                  setIsJobTypeOpen(false);
                }}
              >
                {formData.job_modes.length > 0
                  ? formData.job_modes.join(", ")
                  : "Select Job Mode"}{" "}
                <svg
                  className="h-4 w-4 text-gray-500"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
              {isJobModeOpen && (
                <div className="absolute mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg z-10">
                  {jobModes.map((mode) => (
                    <label
                      key={mode}
                      className="flex items-center px-3 py-2 hover:bg-gray-100"
                    >
                      <input
                        type="checkbox"
                        checked={formData.job_modes.includes(mode)}
                        onChange={() => toggleOption(mode, "job_modes")}
                        className="mr-2"
                      />
                      {mode}
                    </label>
                  ))}
                </div>
              )}
            </div>
            <div>
              <label htmlFor="current_location" className="block mb-2">
                Current Location<span className="text-red-500">*</span>
              </label>
              <select
                id="current_location"
                name="current_location"
                className="w-full border border-gray-300"
                value={formData.current_location}
                onChange={handleChange}
              >
                <option value="" disabled hidden>
                  Current Location
                </option>
                {location.map((items) => (
                  <option key={items} value={items}>
                    {items}
                  </option>
                ))}
              </select>
              {errors.current_location && (
                <span className="text-red-500 text-sm">
                  {errors.current_location}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="preferred_location" className="block mb-2">
                Preferred Location<span className="text-red-500">*</span>
              </label>
              <select
                id="preferred_location"
                name="preferred_location"
                className="w-full border border-gray-300"
                value={formData.preferred_location}
                onChange={handleChange}
              >
                <option value="" disabled hidden>
                  Preferred Location
                </option>
                {location.map((items) => (
                  <option key={items} value={items}>
                    {items}
                  </option>
                ))}
              </select>
              {errors.preferred_location && (
                <span className="text-red-500 text-sm">
                  {errors.preferred_location}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="notice_period" className="block mb-2">
                Notice Period<span className="text-red-500">*</span>
              </label>
              <select
                id="notice_period"
                name="notice_period"
                className="w-full border border-gray-300"
                value={formData.notice_period}
                onChange={handleChange}
              >
                <option value="" disabled hidden>
                  Notice Period
                </option>
                {noticePeriod.map((items) => (
                  <option key={items.name} value={items.name}>
                    {items.name}
                  </option>
                ))}
              </select>
              {errors.notice_period && (
                <span className="text-red-500 text-sm">
                  {errors.notice_period}
                </span>
              )}
            </div>
            {formData.notice_period === "Serving notice period" ||
            formData.notice_period === "Immediate/15 days" ? (
              <div>
                <label htmlFor="preferred_job_role" className="block mb-2">
                  Remaing Working Days<span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  name="remaining_working_days"
                  className="w-full border border-gray-300"
                  placeholder="Number of Days"
                  value={formData.remaining_working_days}
                  onChange={handleChange}
                  onKeyPress={(e) => {
                    if (!/^\d*$/.test(e.key)) {
                      // Prevent non-numeric input
                      e.preventDefault();
                    }
                  }}
                />
                {errors.remaining_working_days && (
                  <span className="text-red-500 text-sm">
                    {errors.remaining_working_days}
                  </span>
                )}
              </div>
            ) : (
              ""
            )}

            <div>
              <label htmlFor="work_experience" className="block mb-2">
                Total Work Experience in months
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="work_experience"
                className="w-full border border-gray-300"
                placeholder="In Months only"
                value={formData.work_experience}
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (!/^\d*$/.test(e.key)) {
                    // Prevent non-numeric input
                    e.preventDefault();
                  }
                }}
              />

              {errors.work_experience && (
                <span className="text-red-500 text-sm">
                  {errors.work_experience}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="current_salary" className="block mb-2">
                Current Salary
                <p className=" ml-1 inline-block text-sm text-gray-400">
                  In LPA
                </p>
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                name="current_salary"
                className="w-full border border-gray-300"
                placeholder="CTC"
                value={formData.current_salary}
                onChange={handleChange}
                onKeyPress={(e) => {
                  if (!/^\d*$/.test(e.key)) {
                    // Prevent non-numeric input
                    e.preventDefault();
                  }
                }}
              />
              {errors.current_salary && (
                <span className="text-red-500 text-sm">
                  {errors.current_salary}
                </span>
              )}
            </div>
            <div>
              <label htmlFor="expected_salary_min" className="block mb-2">
                Expected Salary
                <p className=" ml-1 inline-block text-sm text-gray-400">
                  In LPA
                </p>
                <span className="text-red-500">*</span>
              </label>
              <div className="grid gap-x-6  mb-2 md:grid-cols-2">
                <div>
                  <input
                    type="text"
                    name="expected_salary_min"
                    className="w-full border border-gray-300"
                    placeholder="Min."
                    value={formData.expected_salary_min}
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (!/^\d*$/.test(e.key)) {
                        // Prevent non-numeric input
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.expected_salary_min && (
                    <span className="text-red-500 text-sm">
                      {errors.expected_salary_min}
                    </span>
                  )}
                </div>
                <div>
                  <input
                    type="text"
                    name="expected_salary_max"
                    className="w-full border border-gray-300"
                    placeholder="Max."
                    value={formData.expected_salary_max}
                    onChange={handleChange}
                    onKeyPress={(e) => {
                      if (!/^\d*$/.test(e.key)) {
                        // Prevent non-numeric input
                        e.preventDefault();
                      }
                    }}
                  />
                  {errors.expected_salary_max && (
                    <span className="text-red-500 text-sm">
                      {errors.expected_salary_max}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="preferred_job_role" className="block mb-2">
                Preferred Job Role<span className="text-red-500">*</span>
              </label>
              <select
                id="preferred_job_role"
                name="preferred_job_role"
                className="w-full border border-gray-300"
                value={formData.preferred_job_role}
                onChange={handleChange}
              >
                <option value="" disabled hidden>
                  Select Preferred Job Role
                </option>
                {jobRoles.map((items) => (
                  <option key={items} value={items}>
                    {items}
                  </option>
                ))}
              </select>
              {errors.preferred_job_role && (
                <span className="text-red-500 text-sm">
                  {errors.preferred_job_role}
                </span>
              )}
            </div>
          </div>
          <button
            type="button"
            className="theme-btn bordered-btn py-2.5"
            onClick={(e) => handleSubmit(e)}
          >
            <span className="color-text">
              {" "}
              {isUpdating ? "Update" : "Submit"}
            </span>
          </button>
        </form>
      </div>
    </>
  );
};

export default Prefrence;
