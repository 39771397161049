import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Details from "./details";
import Scheduled from "./scheduled";
import Hiring from "./hiring";
import { useApplicantsContext } from "../all Applicants/applicantsContext";
import axios from "axios";
import config from "../../../config/config";
import { useSelector } from "react-redux";
import ResumeViewer from "./resumeViewer";
import Tooltip from '@mui/material/Tooltip';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DetailTabs = () => {
  const { applicantsId } = useApplicantsContext();
  const loginData = useSelector((state) => state.auth.Auth);

  const [value, setValue] = React.useState(0);
  const [details, setDetails] = useState({});
  const [allDetails, setAllDetails] = useState({});
  const [photoUrl, setPhotoUrl] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const fetchApplicantsDetail = async () => {
    try {
      const response = await axios.get(`${config.apiUrl}employer/${applicantsId}/details`, {
        headers: {
          'Authorization': `Bearer ${loginData.access_token}`,
        },
      });
      const data = response.data;
      setDetails(data.detail_information);
      setAllDetails(data);

      // If `photo_id` exists, fetch the photo
      if (data.detail_information.profile_id) {
        fetchPhoto(data.detail_information.profile_id);
      }
    } catch (error) {
      console.log("Error fetching applicant details:", error);
    }
  };

  const fetchPhoto = async (photoId) => {
    try {
      const response = await axios.get(`${config.apiUrl}employer/get_photo/${photoId}`, {
        headers: {
          Authorization: `Bearer ${loginData.access_token}`,
        },
        responseType: 'blob',
      });
      const photoBlob = URL.createObjectURL(response.data);
      setPhotoUrl(photoBlob);
    } catch (error) {
      console.log("Error fetching photo:", error);
    }
  };

useEffect(() => {
  console.log("Fetching applicant details for:", applicantsId);
  fetchApplicantsDetail();
}, [applicantsId]);
const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            setOpenSnackbar(true); // Show feedback to the user
        }).catch((err) => {
            console.error('Failed to copy text: ', err);
        });
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

  return (
    <>
      <div className="detail-header flex">
        <div className="relative" style={{ width: "20%" }}>
          <div className="profile-pic">
            <img src={photoUrl || "images/default.png"} alt="Applicant Profile" />
          </div>
        </div>
        <div style={{ width: "70%" }} className="flex items-center justify-between mt-3">
          <div>
            <h2>{details.full_name}</h2>
            <h3>{details.role}</h3>
          </div>
          <div>
          <div className="personal-detail">
                <img src="images/icons/phone.svg" className="mr-1" alt="Phone Icon" />
                {details.phone_number}
                <Tooltip title="Copy phone number">
                    <IconButton 
                        onClick={() => copyToClipboard(details.phone_number)}
                        color="black"
                        size="small"
                    >
                        <ContentCopyIcon fontSize="10px"/>
                    </IconButton>
                </Tooltip>
            </div>
            <div className="personal-detail">
                <img src="images/icons/email.svg" className="mr-1" alt="Email Icon" />
                {details.email}
                <Tooltip title="Copy email">
                    <IconButton 
                        onClick={() => copyToClipboard(details.email)}
                        color="black"
                        size="small"
                    >
                        <ContentCopyIcon fontSize="10px"/>
                    </IconButton>
                </Tooltip>
            </div>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={2000}
                onClose={handleCloseSnackbar}
                message="Copied to clipboard"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
          </div>
        </div>
      </div>

      <Box sx={{ minWidth: '700px' }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} className="hirree-tabs">
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Details" {...a11yProps(0)} />
            <Tab label="Resume" {...a11yProps(1)} />
            <Tab label="Interview Schedule" {...a11yProps(2)} />
            <Tab label="Hiring Progress" {...a11yProps(3)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Details ApplicantsDetail={details} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <ResumeViewer />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Scheduled ApplicantsDetail={allDetails} fetchDetails={fetchApplicantsDetail}/>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={3}>
          <Hiring ApplicantsDetail={allDetails} />
        </CustomTabPanel>
      </Box>
    </>
  );
}

export default DetailTabs;
