import React, { useState, useEffect } from "react";
import CandidateLogin from "./candidate/CandidateLogin";
import EmployerLogin from "./employer/EmployerLogin";
import LeftImage from "../design/LeftImage";
import { useSelector } from "react-redux";
import { Navigate, useLocation, Link } from "react-router-dom";
import config from "../../config/config";
import { getCompanyDocs,getCompanyInfo } from "../../utils/employer.api/employer.api.services";
import { getCandidateProfile } from "../../utils/candidate.api/candidate.api.services";
import { useNavigate } from "react-router-dom";
import localStorage from "redux-persist/es/storage";

function Login({ userType: initialType }) {
  const navigate = useNavigate();
  const location = useLocation();
  const defaultUserType =
    initialType || location.state?.userType || "candidate"; // Read `userType` from prop or state
  const [showComponent, setshowComponent] = useState(defaultUserType);
  const [activeBtn, setActiveBtn] = useState(defaultUserType);
  const [redirectTo, setRedirectTo] = useState(null);

  React.useEffect(() => {
    localStorage.removeItem('settingTab')
    localStorage.removeItem('tab')
  }
  , []);

  const handleCandidate = () => {
    setshowComponent("candidate");
    setActiveBtn("candidate");
    navigate("/candidate/login");
  };

  const handleEmployer = () => {
    setshowComponent("employer");
    setActiveBtn("employer");
    navigate("/employer/login");
  };

  const loginData = useSelector((state) => state.auth.Auth);
  const userType = useSelector((state) => state.auth.Auth);

  useEffect(() => {
    const checkProfile = async () => {
      if (loginData.access_token) {
        if (userType.role === "employer") {
          try {
            const profileData = await getCompanyDocs(loginData.access_token);
            if (profileData.data.status === "incomplete" || profileData.data.status === "pending") {
              localStorage.setItem("status", profileData.data.status);
              setRedirectTo(`${config.employerUrl}setting`);
            } else {
              localStorage.setItem("status", profileData.data.status);
              if(profileData.data.status === "approved"){
                try {
                  const response = await getCompanyInfo(loginData.access_token)
                  if (response.data) {
                    navigate("/employer/dashboard");
                  }
                  else {
                    setRedirectTo(`${config.employerUrl}setting`);
                  }
              } catch (error) {
                  console.log(error)
              }
            }
              setRedirectTo(`${config.employerUrl}setting`);
            }
          } catch (error) {
            console.error("Error fetching employer profile:", error);
          }
        } else if (userType.role === "candidate") {
          try {
            const candidateProfileData = await getCandidateProfile(
              loginData.access_token
            );
            if (candidateProfileData.data === null) {
              setRedirectTo(`${config.candidateUrl}upload-resume`);
            } else {
              setRedirectTo(`${config.candidateUrl}dashboard`);
            }
          } catch (error) {
            console.error("Error fetching candidate profile:", error);
          }
        }
      }
    };
    checkProfile();
  }, [loginData.access_token, userType.role]);

  if (loginData.access_token) {
    if (userType.role === "candidate" && redirectTo) {
      return <Navigate to={redirectTo} />;
    } else if (userType.role === "employer" && redirectTo) {
      return <Navigate to={redirectTo} />;
    }
  }

  return (
    <div className="bg-[#F3F9FC] forms">
      <div className="mx-auto sm:max-w-full sm:max-w-[1200px] signup">
        <div className="grid grid-cols-12 gap-4 ">
          <div className="md:col-span-7 col-span-12 h-full sm:h-screen    bg-[#F3F9FC]">
            {activeBtn === "candidate" && <LeftImage />}
            {activeBtn === "employer" && (
              <div className="px-5 pt-10 ">


  {/* Text Section */}
  <div className="text-center max-w-2xl">
    {/* Heading */}
    <h1 className="text-4xl md:text-5xl font-bold text-gray-800 leading-tight mb-4">
      Discover & Hire the Best Talent with{" "}
      <span className="color-text">Hirree</span>!
    </h1>
    {/* Subheading */}
    <p className="text-base md:text-lg text-gray-600 leading-relaxed mb-6">
      Simplify your hiring journey and build winning teams with India’s most
      trusted recruitment platform. Experience seamless talent acquisition,
      tailored to your needs.
    </p>
    {/* Features */}
    <div className="flex justify-center space-x-4 text-sm md:text-base text-gray-500 mb-6">
      <span className="px-4 border-r border-gray-300">Job Posting</span>
      <span className="px-4 border-r border-gray-300">Resdex</span>
      <span className="px-4">Assisted Hiring</span>
    </div>
    {/* CTA Button */}
    <button className="px-8 py-4 theme-btn shadow-md hover:scale-105 hover:shadow-lg transition-transform duration-300 ease-in-out hidden">
      Get Started
    </button>
  </div>
    {/* Image Section */}
    <div className="w-full justify-center mb-8 hidden sm:flex">
    <img
      src="/images/image.png"
      alt="Employer Login"
      className="w-[50%] mt-6 max-w-2xl h-auto transform hover:scale-105 transition-transform duration-300 ease-in-out"
    />
  </div>
</div>


            )}
          </div>
          <div className="bg-white p-5 md:pt-28 md:px-16 md:col-span-5 col-span-12">
            <div className="mx-4 p-md-5">
              <div className="flex justify-center items-center">
                {activeBtn === "candidate" && (
                  <button
                    type="button"
                    className={`me-2 bordered-btn theme-btn ${
                      activeBtn === "candidate" ? "active" : ""
                    }`}
                    onClick={handleCandidate}
                  >
                    Job Seeker
                  </button>
                )}
                {/* {activeBtn === "employer" && (
                <button
                  type="button"
                  className={`me-2 bordered-btn theme-btn ${
                    activeBtn === "employer" ? "active" : ""
                  }`}
                  onClick={handleEmployer}
                >
                  Company
                </button>
                )} */}
              </div>
            </div>
            {showComponent === "employer" && (
              <EmployerLogin redirectPath="/employer/login" />
            )}
            {showComponent === "candidate" && (
              <CandidateLogin redirectPath="/candidate/login" />
            )}
            {activeBtn === "candidate" && (
              <>
                <div className="my-5 relative">
                  <span className="or">or</span>
                </div>
                <div className="footer-content px-4">
                  <p className="py-3">
                    Don't have an account?{" "}
                    <Link
                      to={`/${showComponent}/signup`}
                      state={{ userType: activeBtn }}
                    >
                      Sign Up
                    </Link>
                  </p>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
